import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginatedDto, PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import { MailActivity, MailSent } from '@greco/nestjs-mail-utils';
import { MailServiceModule } from './mail.module';

@Injectable({ providedIn: MailServiceModule })
export class MailService {
  constructor(private http: HttpClient) {}

  // Get(':mailId')
  async getMail(mailId: string): Promise<MailSent> {
    return toPromise(this.http.get<MailSent>(`/api/mail/${mailId}`));
  }
  // Get('paginate')
  async paginateMail(options: PaginatedQueryParams, to?: string): Promise<PaginatedDto<MailSent>> {
    return toPromise(
      this.http.get<PaginatedDto<MailSent>>(`/api/mail/paginate`, {
        params: {
          ...(to && { to }),
          ...(options?.page ? { page: options.page.toString() } : {}),
          ...(options?.limit ? { limit: options.limit.toString() } : {}),
        },
      })
    );
  }

  // Get ('activity/paginate')
  async paginateActivityAll(options: PaginatedQueryParams): Promise<PaginatedDto<MailActivity>> {
    return toPromise(
      this.http.get<PaginatedDto<MailActivity>>(`/api/mail/activity/paginate`, {
        params: {
          ...(options?.page ? { page: options.page.toString() } : {}),
          ...(options?.limit ? { limit: options.limit.toString() } : {}),
        },
      })
    );
  }
  // Get ('activity/paginate/:mailId')
  async paginateActivity(options: PaginatedQueryParams, mailId: string): Promise<PaginatedDto<MailActivity>> {
    return toPromise(
      this.http.get<PaginatedDto<MailActivity>>(`/api/mail/activity/paginate`, {
        params: {
          ...{ mailId },
          ...(options?.page ? { page: options.page.toString() } : {}),
          ...(options?.limit ? { limit: options.limit.toString() } : {}),
        },
      })
    );
  }

  async removeFromBlacklist(email: string) {
    return toPromise(this.http.delete<any>(`/api/mail/blacklist/${email}`));
  }

  async getBlacklist(email: string) {
    return toPromise(this.http.get<any>(`/api/mail/blacklist/${email}`));
  }
}
