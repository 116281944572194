import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '@greco-fit/scaffolding';
import { CommunityAgreement, CommunityAgreementUsage } from '@greco/community-agreements';
import { Community } from '@greco/identity-communities';
import { AgreementUsageByEventDto } from '@greco/nestjs-booking-events';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'greco-link-agreement',
  templateUrl: './link-agreement.dialog.html',
  styleUrls: ['./link-agreement.dialog.scss'],
})
export class LinkAgreementDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      community: Community;
      usages: CommunityAgreementUsage[];
      linkedIds: string[];
      checked: boolean;
      eventId: string;
    },
    public dialogRef: MatDialogRef<LinkAgreementDialog>
  ) {}

  dialogData: DialogData = {
    title: 'Select Agreements to Link',
    showCloseButton: true,
  };

  selected$ = new BehaviorSubject<AgreementUsageByEventDto[] | null>(null);

  confirmed(items: CommunityAgreement[]) {
    this.dialogRef.close(items);
  }
}
