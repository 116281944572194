import { Component, Inject, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User } from '@greco/identity-users';

@Component({
  selector: 'greco-profile-details-update',
  templateUrl: './profile-details-update.dialog.html',
  styleUrls: ['./profile-details-update.dialog.scss'],
})
export class ProfileDetailsUpdateDialog {
  constructor(
    public dialogRef: MatDialogRef<ProfileDetailsUpdateDialog>,
    @Inject(MAT_DIALOG_DATA) private dialogData: { title: string; user: User; readOnly: boolean }
  ) {
    this.userDetails = {
      ...this.dialogData,
    };

    this.readonly = !!this.dialogData?.readOnly;
  }

  @Input() readonly = false;
  @Input() public userDetails: { title: string; user: User; readOnly: boolean };
}
