import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { BrivoStationAccessPoint, BrivoStationAccessPointActivity } from '@greco/domain-brivo';
import { Community } from '@greco/identity-communities';
import { BrivoService } from '@greco/ngx-brivo';
import { SecurityService } from '@greco/ngx-security-util';
import { CheckInSecurityActions, CheckInSecurityResource, Station } from '@greco/stations';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, combineLatest, Observable, of, Subscription as RXJSSubscription } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { StationCheckInListComponent } from '../../components';
import { CheckInService } from '../../services';

@Component({
  selector: 'greco-express-station-page',
  templateUrl: './express-station.page.html',
  styleUrls: ['./express-station.page.scss'],
})
export class ExpressStationPage implements OnInit, OnDestroy {
  constructor(
    private route: ActivatedRoute,
    private securitySvc: SecurityService,
    private checkedInUserSvc: CheckInService,
    private brivoSvc: BrivoService,
    private toastr: ToastrService,
    private snacks: MatSnackBar,
    private breakpointObserver: BreakpointObserver
  ) {}

  @ViewChild('nativeInput') nativeInput?: ElementRef<HTMLInputElement>;
  @ViewChild(MatInput) input?: MatInput;
  @ViewChild(StationCheckInListComponent) stationCheckInListComponent!: StationCheckInListComponent;

  autoCheckIn = true;
  selectedAccountId: string | null = null;
  expanded = false;
  pageSizes = [5, 10, 20, 50];

  timeout: any = null;

  latestAccessPointActivity: BrivoStationAccessPointActivity[] = [];

  isMobile$ = this.breakpointObserver.observe('(max-width: 1000px)').pipe(map(result => result.matches));

  readonly _station$ = new BehaviorSubject<Station | null>(null);
  @Input() set station(station: Station | null) {
    this._station$.next(station);
  }
  get station() {
    return this._station$.value;
  }

  readonly _syncEntries$ = new BehaviorSubject<boolean>(true);
  set syncEntries(syncEntries: boolean) {
    this._syncEntries$.next(syncEntries);
  }
  get syncEntries() {
    return this._syncEntries$.value;
  }

  readonly staticFilter = new RequestQueryBuilder().search({
    'user.id': '',
  });

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  community$: Observable<Community> = this.route.parent!.parent!.data.pipe(map(data => data.community));

  public readonly gateControl = new FormControl(null as BrivoStationAccessPoint | null);

  $gateValueChanges: RXJSSubscription | null = null;

  readonly _selectInput$ = combineLatest([this.community$, this._station$]).pipe(
    tap(() => {
      this.selectInput();
    })
  );

  saving = false;

  canReadCheckInList$ = this.community$.pipe(
    switchMap(async community => {
      return await this.securitySvc.hasAccess(CheckInSecurityResource.key, CheckInSecurityActions.READ, {
        communityId: community.id,
      });
    }),
    shareReplay(1)
  );

  // canOpenAccessPoint$ = this.community$.pipe(
  //   switchMap(async community =>
  //     community
  //       ? await this.communitySecuritySvc.hasAccess(
  //           community.id,
  //           BrivoSecurityResource.key,
  //           BrivoSecurityActions.OPEN_ACCESS_POINT
  //         )
  //       : false
  //   ),
  //   shareReplay(1)
  // );

  // canLinkAccessPoint$ = this.community$.pipe(
  //   switchMap(async community =>
  //     community
  //       ? await this.communitySecuritySvc.hasAccess(
  //           community.id,
  //           BrivoSecurityResource.key,
  //           BrivoSecurityActions.LINK_ACCESS_POINT
  //         )
  //       : false
  //   ),
  //   shareReplay(1)
  // );

  brivoCommunitySite$ = this.community$.pipe(
    switchMap(community => (community ? this.brivoSvc.getCommunitySite(community.id) : of(null))),
    tap(site => (this.syncEntries = !!site))
  );

  private _refreshAccessPoints$ = new BehaviorSubject(null);

  linkedAccessPoints$ = combineLatest([this.brivoCommunitySite$, this._station$, this._refreshAccessPoints$]).pipe(
    switchMap(([site, station]) => (site && station ? this.brivoSvc.getAccessPointsForStation(station.id) : of([]))),
    tap(accessPoints => {
      if (!this.gateControl.value) {
        this.gateControl.setValue(accessPoints?.length ? accessPoints[0] : null);
      }
    })
  );

  async pasteMemberNumber(event: ClipboardEvent) {
    const data = event.clipboardData?.getData('text') || '';

    await this.expressCheckIn(data);
  }

  onInputChange(data: string) {
    clearTimeout(this.timeout);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const $this = this;
    this.timeout = setTimeout(function () {
      $this.expressCheckIn(data);
    }, 500);
  }

  async expressCheckIn(data: string) {
    this.selectInput();

    if (!data || !this.station) return;
    const response = await this.checkedInUserSvc.expressCheckIn({
      accessPoint: this.gateControl.value || undefined,
      data,
      stationId: this.station.id,
    });

    if (!response) {
      this.snacks.open('Invalid Member Number', 'Ok', { duration: 2500, panelClass: 'mat-warn' });
      return;
    }

    const latestActivity = response.accessPointActivity;
    if (latestActivity) {
      const user = latestActivity.user;
      //temporarily while gate scanners don't work, instead of the autoSync feature
      const toast = latestActivity.allowedAccess
        ? this.toastr.success(`Opened at ${latestActivity.accessPointName}`, user?.displayName, {
            timeOut: 5000,
            progressBar: true,
            progressAnimation: 'increasing',
          })
        : this.toastr.error(`Denied at ${latestActivity.accessPointName}`, user?.displayName, {
            timeOut: 5000,
            progressBar: true,
            progressAnimation: 'increasing',
          });

      toast.toastRef.componentInstance.user = user;

      //not needed for express
      //toast.onTap.pipe(take(1)).subscribe(async () => await this.activityToastClicked(user));
    } else if (response.checkIn) {
      const user = response.contact.user;
      const toast = this.toastr.success(`Checked In!`, user?.displayName, {
        timeOut: 5000,
        progressBar: true,
        progressAnimation: 'increasing',
      });

      toast.toastRef.componentInstance.user = user;
    }

    this.stationCheckInListComponent.refreshList();
  }

  selectInput() {
    if (this.input) {
      this.input.value = '';
      if (this.nativeInput?.nativeElement) this.nativeInput.nativeElement.value = '';
      setTimeout(() => this.nativeInput?.nativeElement.select());
    }
  }

  toggleAutoCheckIn() {
    this.autoCheckIn = !this.autoCheckIn;
    localStorage.setItem('autoCheckIn' + this.station?.id, this.autoCheckIn ? 'true' : 'false');
  }

  async ngOnInit() {
    this.selectInput();
    // this.autoCheckIn = localStorage.getItem('autoCheckIn' + this.station?.id) === 'false' ? false : true;
    this.autoCheckIn = true;

    const storedGate = localStorage.getItem('gate' + this.station?.id);

    if (storedGate) {
      this.gateControl.setValue(JSON.parse(storedGate));
    }

    this.$gateValueChanges = this.gateControl.valueChanges.subscribe(value => {
      value
        ? localStorage.setItem('gate' + this.station?.id, JSON.stringify(value))
        : localStorage.removeItem('gate' + this.station?.id);
    });
    //not needed on express while gate scanners are broken
    // await this.getMostRecentAccessPointActivity(false);
  }

  ngOnDestroy(): void {
    this.$gateValueChanges?.unsubscribe();
  }

  async openGate(accessPointExternalId?: string) {
    this.selectInput();

    if (!accessPointExternalId || !this.station) return;

    await this.brivoSvc.openAccessPoint(this.station.id, accessPointExternalId);
  }

  async getMostRecentAccessPointActivity(showToast = true) {
    if (!this.station) return;

    const latestActivities = await this.brivoSvc.getMostRecentStationAccessPointActivity(this.station.id);

    if (!showToast) {
      this.latestAccessPointActivity = latestActivities;
      return;
    }

    for (const latestActivity of latestActivities) {
      if (this.latestAccessPointActivity.some(alreadySeen => alreadySeen.id === latestActivity.id)) {
        continue;
      }

      const user = latestActivity.user;

      const toast = latestActivity.allowedAccess
        ? this.toastr.success(`Opened at ${latestActivity.accessPointName}`, user?.displayName, {
            timeOut: 5000,
            progressBar: true,
            progressAnimation: 'increasing',
          })
        : this.toastr.error(`Denied at ${latestActivity.accessPointName}`, user?.displayName, {
            timeOut: 5000,
            progressBar: true,
            progressAnimation: 'increasing',
          });

      toast.toastRef.componentInstance.user = user;

      //not needed for express
      //toast.onTap.pipe(take(1)).subscribe(async () => await this.activityToastClicked(user));
    }

    this.latestAccessPointActivity = latestActivities;
  }
}
