import { User } from '@greco/identity-users';
import { UserPerk } from '@greco/sales-perks';
import { Purchase } from '@greco/sales-purchases';
import { BookingOption } from './booking-option';
import { BookingRequirementSubmission } from './booking-requirement';
import { CalendarEvent } from './event';
import { RoomResourceSpot } from './resource';

export enum BookingStatus {
  VOIDED = 'VOIDED',
  PENDING = 'PENDING',

  /** The booking was properly created and now uses a space in the event */
  CONFIRMED = 'CONFIRMED',

  /** */
  CHECKED_IN = 'CHECKED_IN',

  /** */
  CANCELLED = 'CANCELLED',

  /** */
  LATE_CANCELLED = 'LATE_CANCELLED',

  /** */
  NO_SHOW = 'NO_SHOW',
}

export interface Booking {
  id: string;
  status: BookingStatus;

  created: Date;
  modified: Date;

  event: CalendarEvent;

  bookingOption: BookingOption;
  bookingOptionConsumed: boolean;
  bookingOptionUserPerk?: UserPerk;

  boostersUsed: number;
  boostersPurchased: number;

  user: User;
  createdBy?: User;

  modifiedById?: string;
  modifiedBy?: User;

  bookedById: string;
  bookedBy: User;
  useBookedByPerks: boolean;

  requirementSubmissions: BookingRequirementSubmission[];

  purchases?: Purchase[];

  spotsTaken?: number;
  spotId?: string;
  spot?: RoomResourceSpot;
}
