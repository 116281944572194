import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FiltersModule } from '@greco/ngx-filters';
import { PaymentsModule } from '@greco/ngx-finance-payments';
import { AuthModule } from '@greco/ngx-identity-auth';
import { LetModule } from '@greco/ngx-let-util';
import { AddressInputModule } from '@greco/ui-address-input';
import { CollapsibleSectionModule } from '@greco/ui-collapsible-section';
import { SimpleDialogModule } from '@greco/ui-dialog-simple';
import { FormSaveButtonsModule } from '@greco/ui-form-save-buttons';
import { TableModule } from '@greco/ui-table';
import { UserAvatarModule } from '@greco/ui-user-avatar';
import { SignaturePadModule } from 'angular2-signaturepad';
import { NgxMaskModule } from 'ngx-mask';
import { WebcamModule } from 'ngx-webcam';
import {
  ManageUserAuthComponent,
  SignatureComponent,
  UserAccountDetailsComponent,
  UserPickerComponent,
  UserProfileDetailsComponent,
  UserProfileDetailsOptionalComponent,
} from './components';
import { ChangePasswordDialog, GrantSuperAdminDialog, ProfileDetailsUpdateDialog, WebcamDialog } from './dialogs';
import { ChangeProfilePictureDialog } from './dialogs/change-profile-picture';
import { AdminPageComponent, AdminSearchFilter } from './pages';
import { AdminService, SignatureService, UserService } from './services';

@NgModule({
  imports: [
    // Angular
    CommonModule,
    RouterModule,
    ReactiveFormsModule,

    // Material
    MatIconModule,
    MatChipsModule,
    MatMenuModule,
    MatInputModule,
    MatRadioModule,
    MatButtonModule,
    MatDialogModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatAutocompleteModule,
    MatSelectModule,

    // Greco
    LetModule,
    AuthModule,
    TableModule,
    FiltersModule,
    PaymentsModule,
    UserAvatarModule,
    AddressInputModule,
    SimpleDialogModule,
    FormSaveButtonsModule,
    CollapsibleSectionModule,

    // Other
    SignaturePadModule,
    NgxMaskModule.forChild(),
    WebcamModule,
  ],
  declarations: [
    AdminPageComponent,
    UserPickerComponent,
    GrantSuperAdminDialog,
    ManageUserAuthComponent,
    UserAccountDetailsComponent,
    UserProfileDetailsComponent,
    UserProfileDetailsOptionalComponent,
    SignatureComponent,
    ChangePasswordDialog,
    WebcamDialog,
    ChangeProfilePictureDialog,
    ProfileDetailsUpdateDialog,
  ],
  providers: [AdminService, UserService, SignatureService, AdminSearchFilter],
  exports: [
    AdminPageComponent,
    UserPickerComponent,
    ManageUserAuthComponent,
    UserProfileDetailsComponent,
    UserProfileDetailsOptionalComponent,
    UserAccountDetailsComponent,
    SignatureComponent,
    WebcamDialog,
    ChangeProfilePictureDialog,
    ChangePasswordDialog,
    ProfileDetailsUpdateDialog,
  ],
})
export class UsersModule {}
