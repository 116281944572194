<ng-container *ngIf="purchase">
  <ng-container *grecoLet="linkedAccounts$ | async as linkedAccounts">
    <ng-container *grecoLet="accountCategories$ | async as categoryData">
      <div class="header" *ngIf="showHeader">
        <div class="user-photo">
          <greco-smart-img
            class="userImageToExport"
            [src]="purchase.user.photoURL || 'assets/lf3/icon_square_pad.png'"
            [border]="true"
            [round]="true"
          ></greco-smart-img>
        </div>

        <div style="display: flex; flex-direction: row">
          <div class="purchase-for">
            <div style="display: flex; flex-direction: row; gap: 4px">
              <h3 style="display: flex; flex-direction: row">Purchase For</h3>
              <button
                *ngIf="linkedAccounts?.length"
                mat-icon-button
                matTooltip="Select another user"
                [matMenuTriggerFor]="menu"
                #menuTrigger="matMenuTrigger"
                style="margin-top: -10px; margin-bottom: -10px; margin-left: -4px"
              >
                <mat-icon [style]="menuTrigger.menuOpen ? 'transform: rotate(180deg)' : ''">
                  expand_circle_down
                </mat-icon>
              </button>

              <mat-menu #menu>
                <ng-container *ngFor="let link of linkedAccounts">
                  <button
                    *ngIf="link.status === 'ACTIVE'"
                    mat-menu-item
                    [disabled]="link.account?.id === purchase.user.id"
                    (click)="changePurchaseFor(link.account!)"
                  >
                    <p *ngIf="link.account?.id !== purchase.purchasedById; else yourself">
                      {{ link.account?.displayName }}
                    </p>
                    <ng-template #yourself>
                      <p>Yourself</p>
                    </ng-template>
                  </button>
                </ng-container>
              </mat-menu>
            </div>

            <a
              [routerLink]="['/admin/community', communityId, purchase.user.id]"
              [target]="openContactInNewTab ? '_blank' : '_self'"
              [matTooltip]="purchase.user.contactEmail"
              (click)="$event.stopImmediatePropagation()"
            >
              <p>{{ purchase.user.displayName }}</p>
              <p style="font-style: italic">{{ purchase.user.contactEmail || 'Failed to load user data' }}</p>
            </a>
          </div>

          <mat-divider
            *ngIf="purchase.user.id !== purchase.purchasedById"
            [vertical]="true"
            style="margin-left: 24px; margin-right: 24px; max-height: 64px"
          ></mat-divider>

          <div class="billed-to" *ngIf="purchase.user.id !== purchase.purchasedById">
            <h3>Billed To</h3>
            <a
              [routerLink]="['/admin/community', communityId, purchase.purchasedById]"
              [target]="openContactInNewTab ? '_blank' : '_self'"
              [matTooltip]="purchase.purchasedBy?.contactEmail || ''"
              (click)="$event.stopImmediatePropagation()"
            >
              <p>{{ purchase.purchasedBy?.displayName }}</p>
              <p style="font-style: italic">{{ purchase.purchasedBy?.contactEmail || 'Failed to load user data' }}</p>
            </a>
          </div>
        </div>

        <div class="detailsPurchase" *ngIf="showPurchaseInfo">
          <strong>Account</strong>
          <span [matTooltip]="purchase.account.id">{{ purchase.account.name }}</span>

          <strong>Purchase ID</strong>
          <span>{{ purchase.id }}</span>

          <strong *ngIf="purchase.items && purchase.items.length && purchase.items[0].saleCategory">
            Sales Category
          </strong>

          <span
            *ngIf="purchase.items && purchase.items.length && purchase.items[0].saleCategory"
            [matTooltip]="purchase.items[0].saleCategoryId || ''"
          >
            {{ (purchase.items | pluck : 'saleCategory.label').join(', ') }}
          </span>

          <strong>{{ 'Created on' }}</strong>
          <span>{{ purchase.created | date }}</span>

          <strong *ngIf="purchase.completedOn">{{ 'Paid on' }}</strong>
          <span *ngIf="purchase.completedOn">{{ (purchase.completedOn | date) || '-' }}</span>

          <strong>Sold By</strong>
          <span>{{ purchase.soldBy?.displayName || purchase.soldBy?.contactEmail || '-' }}</span>

          <ng-container *ngIf="purchase.referredBy?.displayName || purchase.referredByEmail as referredBy">
            <strong>Referred By</strong>
            <span>{{ referredBy }}</span>
          </ng-container>
        </div>
      </div>

      <div class="content">
        <greco-purchase-preview-table
          [items]="(items$ | async) || []"
          [inventories]="inventories"
          [isPurchaseVoided]="$any(purchase)?.status === 'VOIDED'"
          [accountId]="purchase.account.id"
          [categoryData]="categoryData || []"
          [edit]="edit"
          [disabledEdit]="disabledEdit"
          (refresh)="refresh.emit()"
        ></greco-purchase-preview-table>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
