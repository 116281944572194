import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MailSent } from '@greco/nestjs-mail-utils';
import { IPaginationMeta } from 'nestjs-typeorm-paginate';
import { MailService } from '../../services';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'greco-mail-page',
  templateUrl: './mail.page.html',
  styleUrls: ['./mail.page.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class MailPage implements OnInit {
  constructor(private mailSvc: MailService, private router: Router, private route: ActivatedRoute) {}

  mail: MailSent[] = [];
  pagination: null | IPaginationMeta = null;

  loading = true;

  async loadPage(page: number, limit: number) {
    this.loading = true;
    ({ items: this.mail, meta: this.pagination } = await this.mailSvc.paginateMail({ page, limit }));
    this.loading = false;
  }

  async openActivity(mailSent: MailSent) {
    await this.router.navigate([mailSent.id], { relativeTo: this.route });
    return;
  }

  async ngOnInit(): Promise<void> {
    await this.loadPage(1, 10);
  }
}
