<div class="box-container">
  <greco-box>
    <div class="box-header">
      <mat-icon class="icon background">email</mat-icon>
      <h3>{{mail.from}}</h3>
    </div>
    <p><strong>To:</strong> {{mail.to}}</p>
    <p><strong>Timestamp:</strong> {{mail.created | date}} {{mail.created| date: 'shortTime' }}</p>
    <button mat-stroked-button style="margin-top: 10px" color="primary" (click)="previewEmail()">
      <mat-icon>remove_red_eye</mat-icon>
      <span>Preview</span>
    </button>
    <br />
  </greco-box>
</div>

<greco-table [data]="activity" [loading]="loading">
  <p *grecoTableCol="''; fitContent:true; let event" [matTooltip]="event.id">
    <ng-container [ngSwitch]="event.activityType">
      <mat-icon *ngSwitchCase="'MailSend'">send</mat-icon>
      <mat-icon *ngSwitchCase="'MailOpen'">mark_email_read</mat-icon>
      <mat-icon *ngSwitchCase="'MailBounce' || 'Reject'">report_gmailerrored</mat-icon>
      <mat-icon *ngSwitchCase="'MailDelivery'">mark_email_unread</mat-icon>
      <mat-icon *ngSwitchCase="'MailClick'">ads_click</mat-icon>
      <mat-icon *ngSwitchCase="'MailComplaint'">unsubscribe</mat-icon>
    </ng-container>
  </p>

  <p *grecoTableCol="'Event Type'; let event" [matTooltip]="event.mailEventType">{{event.mailEventType | titlecase}}</p>
  <p *grecoTableCol="'Timestamp'; let event" [matTooltip]="event.timestamp | date: 'full'">
    {{event.timestamp | date}} {{event.timestamp | date: 'shortTime'}}
  </p>
  <p *grecoTableCol="'IP Address'; let event" [matTooltip]="event.ipAddress">{{event.ipAddress}}</p>
  <p *grecoTableCol="'User Agent'; let event" [matTooltip]="event.userAgent">{{event.userAgent}}</p>
  <p *grecoTableCol="'Link Tags'; let event" [matTooltip]="event.linkTags">{{event.linkTags}}</p>
</greco-table>

<mat-paginator
  *ngIf="pagination?.totalItems"
  showFirstLastButtons
  [length]="pagination!.totalItems || 0"
  [pageSize]="pagination!.itemsPerPage || 10"
  [pageSizeOptions]="[10, 20, 50]"
  (page)="loadPage($event.pageIndex + 1, $event.pageSize)"
></mat-paginator>
