import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { toPromise } from '@greco-fit/util';
import { EventBookingSecurityResource, EventBookingSecurityResourceAction } from '@greco/booking-events';
import { AccountResource, AccountResourceAction } from '@greco/finance-accounts';
import { Community } from '@greco/identity-communities';
import { Contact, ContactResource, ContactResourceAction } from '@greco/identity-contacts';
import { User, UserResource, UserResourceAction } from '@greco/identity-users';
import { AlertsService } from '@greco/ngx-alerts';
import { BookingService } from '@greco/ngx-booking-events';
import { UserCommunityAgreementsService } from '@greco/ngx-community-agreements';
import { CommunityService } from '@greco/ngx-identity-communities';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { CreateMemberNumberDialog } from '@greco/ngx-identity-contacts';
import { ProfileDetailsUpdateDialog } from '@greco/ngx-identity-users';
import { PerkService } from '@greco/ngx-sales-perks';
import { SubscriptionsService } from '@greco/ngx-sales-subscriptions';
import { SecurityService } from '@greco/ngx-security-util';
import { CreateQuickPurchaseDialog } from '@greco/ngx-shop';
import { PropertyListener } from '@greco/property-listener-util';
import { PurchaseResource, PurchaseResourceAction } from '@greco/sales-purchases';
import {
  Subscription,
  SubscriptionResource,
  SubscriptionResourceAction,
  SubscriptionStatus,
} from '@greco/sales-subscriptions';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import moment from 'moment';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { ActiveSubscriptionDialog, UserAccountDetailsDialog } from '../../dialogs';
import { CheckInService } from '../../services';

@Component({
  selector: 'greco-user-detail-card',
  templateUrl: './user-detail-card.component.html',
  styleUrls: ['./user-detail-card.component.scss'],
})
export class UserDetailCardComponent {
  constructor(
    private matDialog: MatDialog,
    private perksSvc: PerkService,
    private alertSvc: AlertsService,
    private checkInSvc: CheckInService,
    private bookingSvc: BookingService,
    private securitySvc: SecurityService,
    private communitySvc: CommunityService,
    private comSecSvc: CommunitySecurityService,
    private subscriptionSvc: SubscriptionsService,
    private agreementSvc: UserCommunityAgreementsService
  ) {}

  @PropertyListener('saleCategoryIds') _saleCategoryIds$ = new BehaviorSubject<string>('');
  @Input() saleCategoryIds!: string; // Single string to trigger change detection

  @Output() refreshBookingTable = new EventEmitter<string>();

  @Output() refreshPurchaseHistory = new EventEmitter<string>();

  @Output() checkInUser = new EventEmitter<User>();

  @Input() autoCheckIn!: boolean;

  checkedIn = false;

  moment = moment;

  loadingHighPriorityWarnings = false;
  loadingLowPriorityWarnings = false;

  isSuper$ = this.securitySvc.hasAccess(AccountResource.key, AccountResourceAction.CREATE, {}, true);
  canManageContact$ = this.securitySvc.hasAccess(ContactResource.key, ContactResourceAction.UPDATE, {}, true);
  canManageAuth$ = this.securitySvc.hasAccess(UserResource.key, UserResourceAction.MANAGE_AUTH, {}, true);

  readonly _refreshSubscriptions$ = new BehaviorSubject<null>(null);
  readonly _refreshPurchases$ = new BehaviorSubject<null>(null);
  readonly _refreshBookings$ = new BehaviorSubject<null>(null);
  readonly _refreshAgreements$ = new BehaviorSubject<null>(null);

  private _contact$ = new BehaviorSubject<Contact | null>(null);
  @Input() get contact() {
    return this._contact$.value;
  }
  set contact(contact) {
    this.checkedIn = false;
    this._contact$.next(contact);
  }

  private _community$ = new BehaviorSubject<Community | null>(null);
  @Input() get community() {
    return this._community$.value;
  }
  set community(community) {
    this._community$.next(community);
  }

  private _currentUser$ = new BehaviorSubject<User | null>(null);
  @Input() get currentUser() {
    return this._currentUser$.value;
  }
  set currentUser(_currentUser) {
    this._currentUser$.next(_currentUser);
  }

  readonly communityData$ = this._community$.pipe(
    switchMap(async id => (id?.id ? await this.communitySvc.getCommunity(id.id) : null)),
    map(community => (community ? { communityId: community.id, account: community.financeAccount } : null))
  );

  lastCheckIn$ = this._contact$.pipe(
    switchMap(contact => this.checkInSvc.getLastCheckInByUserId(contact?.user?.id || ''))
  );

  canReadPurchases$ = this._community$.pipe(
    switchMap(async community =>
      community
        ? await this.comSecSvc.hasAccess(community.id, PurchaseResource.key, PurchaseResourceAction.READ)
        : false
    ),
    shareReplay(1)
  );

  isGuest$ = this._contact$.pipe(
    switchMap(async contact => {
      // User has a guest booking
      if (
        (
          await this.bookingSvc.getByDate({
            startDate: moment().startOf('day').toDate(),
            endDate: moment().endOf('day').toDate(),
            userId: contact?.user?.id,
          })
        ).some(b => b.bookingOption?.title?.toLowerCase()?.includes('guest'))
      ) {
        return true;
      }

      // User has a guest perk
      if (
        (await this.perksSvc.getUserPerks(contact?.user?.id)).some(p => p.perk?.title?.toLowerCase()?.includes('guest'))
      ) {
        return true;
      }

      return false;
    })
  );

  hasAgreements$ = combineLatest([this._contact$, this._community$]).pipe(
    switchMap(async ([contact, community]) =>
      contact?.user && community
        ? await this.agreementSvc.paginateUserAgreements(RequestQueryBuilder.create(), contact.user.id, { limit: 1 })
        : null
    ),
    map(agreements => !!agreements?.meta?.totalItems),
    startWith(true)
  );

  unsignedAgreements$ = combineLatest([this._contact$, this._refreshAgreements$]).pipe(
    switchMap(async ([contact]) =>
      contact?.user?.id ? this.agreementSvc.getUnsignedUserAgreements(contact.user.id) : []
    )
  );

  subscriptions$ = combineLatest([this._contact$, this._refreshSubscriptions$]).pipe(
    switchMap(([contact]) =>
      this.subscriptionSvc.getPaginatedSubscriptionsByUserAndAccount(
        new RequestQueryBuilder().search({
          status: 'ACTIVE',
        }),
        {
          limit: 10,
          page: '1',
        },
        undefined,
        contact?.user?.id
      )
    )
  );

  canCreatePurchase$ = combineLatest([this._community$, this._currentUser$]).pipe(
    switchMap(([community, currentUser]) =>
      this.securitySvc.hasAccess(PurchaseResource.key, PurchaseResourceAction.PURCHASE_AS_USER, {
        accountId: community?.financeAccountId,
        userId: currentUser?.id,
      })
    )
  );

  canCreateBookings$ = this._community$.pipe(
    switchMap(async community =>
      community
        ? await this.comSecSvc.hasAccess(
            community.id,
            EventBookingSecurityResource.key,
            EventBookingSecurityResourceAction.CREATE
          )
        : false
    )
  );

  canReadSubscriptions$ = combineLatest([this._community$, this._contact$]).pipe(
    switchMap(([community, contact]) =>
      this.securitySvc.hasAccess(SubscriptionResource.key, SubscriptionResourceAction.READ, {
        accountId: community?.financeAccount?.id,
        userId: contact?.user?.id,
      })
    ),
    shareReplay(1)
  );

  highPriorityWarnings$ = combineLatest([
    this._community$,
    this._contact$,
    this._refreshAgreements$,
    this._refreshPurchases$,
  ]).pipe(
    tap(() => (this.loadingHighPriorityWarnings = true)),
    switchMap(async ([community, contact]) => {
      if (!contact?.user || !community) return;
      return await this.alertSvc.getHighPriorityAlerts(contact.user.id);
    }),
    tap(() => (this.loadingHighPriorityWarnings = false))
  );

  lowPriorityWarnings$ = combineLatest([this._community$, this._contact$, this._refreshBookings$]).pipe(
    tap(() => (this.loadingLowPriorityWarnings = true)),
    switchMap(async ([community, contact]) => {
      if (!contact?.user || !community) return;
      return await this.alertSvc.getLowPriorityAlerts(contact.user.id, community.id);
    }),
    tap(() => (this.loadingLowPriorityWarnings = false))
  );

  isHold = (name: string) => name?.toLowerCase()?.includes('hold');
  isFrozen = (status: SubscriptionStatus) => status === SubscriptionStatus.FROZEN;

  async createCustomerPurchase(contact: Contact): Promise<void | null> {
    const communityData = await toPromise(this.communityData$);
    if (!communityData) return null;
    const dialog = this.matDialog.open(CreateQuickPurchaseDialog, {
      data: { accountId: communityData.account.id, communityId: communityData.communityId, contact: contact },
      width: '1000px',
      maxWidth: '90%',
    });
    await toPromise(dialog.afterClosed());
    this._refreshSubscriptions$.next(null);
    this.refreshPurchaseHistory.emit('NewPurchase');
  }

  async openSubscriptionSummary(subscription: Subscription, communityId: string) {
    const dialog = this.matDialog.open(ActiveSubscriptionDialog, {
      data: {
        showCloseButton: false,
        user: this.contact?.user,
        communityId: communityId,
        subscription,
      } as { user: User; communityId: string; subscription: Subscription },
    });
    await toPromise(dialog.afterClosed());
  }

  async userProfileUpdate(user: User | undefined, readOnly: boolean | null) {
    if (user) {
      const dialog = this.matDialog.open(ProfileDetailsUpdateDialog, {
        width: '1000px',
        data: {
          title: 'Profile Information',
          user,
          readOnly,
        } as { title: string; user: User; readOnly: boolean },
      });

      const userDetail: User = await toPromise(dialog.afterClosed());
      if (userDetail && this.contact) {
        this.contact['user'] = userDetail;
        this._contact$.next(this.contact);
      }
    }
  }

  async userAccountUpdate(contact: Contact, readOnly: boolean | null) {
    if (contact) {
      const dialog = this.matDialog.open(UserAccountDetailsDialog, {
        width: '1000px',
        data: {
          title: 'Account Information',
          contact,
          readOnly,
        } as { title: string; contact: Contact; readOnly: boolean },
      });

      const userDetail: User = await toPromise(dialog.afterClosed());
      if (userDetail && this.contact) {
        this.contact['user'] = userDetail;
        this._contact$.next(this.contact);
      }
    }
  }
  async openDialog() {
    const dialog = this.matDialog.open(CreateMemberNumberDialog, {
      data: { contact: this.contact, buttons: [] },
    });
    await toPromise(dialog.afterClosed());
  }

  refreshPurchase(purchaseStatus: string) {
    if (purchaseStatus === 'RetriedPurchase' || purchaseStatus === 'VoidedPurchase') {
      this._refreshPurchases$.next(null);
    }
  }

  refreshAgreements() {
    this._refreshAgreements$.next(null);
  }

  refreshBookings(bookingStatus: string) {
    if (bookingStatus === 'TriedConfirming' || bookingStatus === 'IsPending') {
      this._refreshBookings$.next(null);
      this.refreshBookingTable.emit(bookingStatus);
    }
  }

  checkInUserEmit() {
    this.checkedIn = true;
    this.checkInUser.emit(this.contact?.user);
  }
}
