import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PaginatedQueryParams } from '@greco-fit/nest-utils';
import { toPromise } from '@greco-fit/util';
import {
  Booking,
  BookingStatus,
  CalendarEvent,
  EventBookingSecurityResource,
  EventBookingSecurityResourceAction,
  EventSeries,
  getCancellationPolicyInnerHtml,
  ResourceType,
  RoomResource,
  RoomResourceSpot,
} from '@greco/booking-events';
import { ContactResource, ContactResourceAction } from '@greco/identity-contacts';
import { UserService } from '@greco/ngx-identity-auth';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { ResponsePreviewDialog } from '@greco/ngx-typeform';
import { PropertyListener } from '@greco/property-listener-util';
import { FormResponse } from '@greco/typeform';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import moment from 'moment';
import type { IPaginationMeta } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs';
import { debounceTime, filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { AddBookingOptionDialog } from '../../dialogs';
import { BookingService, CalendarService } from '../../services';

@Component({
  selector: 'greco-bookings-table',
  templateUrl: './bookings-table.component.html',
  styleUrls: ['./bookings-table.component.scss'],
})
export class BookingsTableComponent implements OnDestroy {
  constructor(
    private snacks: MatSnackBar,
    private userSvc: UserService,
    private matDialog: MatDialog,
    private bookingSvc: BookingService,
    private calendarService: CalendarService,
    private communitySecuritySvc: CommunitySecurityService
  ) {}

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  @PropertyListener('event') private _event$ = new ReplaySubject<CalendarEvent>(1);
  @Input() event!: CalendarEvent | EventSeries;

  @PropertyListener('filters') private _filters$ = new BehaviorSubject<RequestQueryBuilder | undefined>(undefined);
  @Input() filters?: RequestQueryBuilder;

  @PropertyListener('communityId') private _communityId$ = new BehaviorSubject<string>('');
  @Input() communityId?: string;

  @PropertyListener('calendarIds') private _calendarIds$ = new BehaviorSubject<string[]>([]);
  @Input() calendarIds?: string[];

  @PropertyListener('sortAlphabetically') private _sortAlphabetically$ = new ReplaySubject<boolean>(1);
  @Input() sortAlphabetically = false;

  @PropertyListener('showLinkedBookings') showLinkedBookings$ = new ReplaySubject<boolean>(1);
  @Input() showLinkedBookings = false;

  @Input() hideCheckIn?: boolean;
  @Input() hideEvent?: boolean;
  @Input() hideDate?: boolean;
  @Input() hideUser?: boolean;
  @Input() hideTags?: boolean;
  @Input() hideSpot?: boolean;
  @Input() hideCommunity?: boolean;
  @Input() hideResource = true;

  @Input() onlyToday = false;
  @Input() sortByEventDate = false;

  @Input() showSpotNumber = false;
  @Input() allowSpotReassignment = true;

  @Input() onlyStaffCalendars = false;

  @Input() allowNoCommunityId = false;

  @Output() rowClick = new EventEmitter<Booking>();

  @Output() totalBookingsToCheckIn = new EventEmitter<number>();
  @Output() bookingsToCheckInChange = new EventEmitter<string[]>();

  moment = moment;
  now = new Date();

  loading = true;
  eventHasRoom = false;
  metadata?: IPaginationMeta;

  totalCheckInBookings = 0;
  bookingsToCheckIn: string[] = [];

  readonly pagination$ = new BehaviorSubject<Partial<PaginatedQueryParams>>({ limit: 50 });

  _self$ = this.userSvc.getUserId();

  permissions$ = this._communityId$.pipe(
    filter(c => !!c),
    switchMap(async communityId => {
      const [cancel, noShow, noShow2, noShow24, checkIn, cancelFree, manageSpots, manageUser] = await Promise.all([
        this.communitySecuritySvc.hasAccess(
          communityId,
          EventBookingSecurityResource.key,
          EventBookingSecurityResourceAction.CANCEL
        ),
        this.communitySecuritySvc.hasAccess(
          communityId,
          EventBookingSecurityResource.key,
          EventBookingSecurityResourceAction.NO_SHOW
        ),
        this.communitySecuritySvc.hasAccess(
          communityId,
          EventBookingSecurityResource.key,
          EventBookingSecurityResourceAction.UNDO_NO_SHOW_2
        ),
        this.communitySecuritySvc.hasAccess(
          communityId,
          EventBookingSecurityResource.key,
          EventBookingSecurityResourceAction.UNDO_NO_SHOW_24
        ),
        this.communitySecuritySvc.hasAccess(
          communityId,
          EventBookingSecurityResource.key,
          EventBookingSecurityResourceAction.CHECK_IN
        ),
        this.communitySecuritySvc.hasAccess(
          communityId,
          EventBookingSecurityResource.key,
          EventBookingSecurityResourceAction.CANCEL_FREE_OF_CHARGE
        ),
        this.communitySecuritySvc.hasAccess(
          communityId,
          EventBookingSecurityResource.key,
          EventBookingSecurityResourceAction.MANAGE_SPOTS
        ),
        this.communitySecuritySvc.hasAccess(communityId, ContactResource.key, ContactResourceAction.READ),
      ]);

      return { cancel, noShow, noShow2, noShow24, checkIn, cancelFree, manageSpots, manageUser };
    }),
    shareReplay(1)
  );

  readonly bookings$ = combineLatest([
    this._communityId$,
    this._filters$,
    this.pagination$,
    this._calendarIds$,
    this.showLinkedBookings$,
    this._sortAlphabetically$,
  ]).pipe(
    tap(() => (this.loading = true)),
    debounceTime(500),
    switchMap(async ([communityId, filters, pagination, calIds, showLinkedBookings]) => {
      if (!communityId && !this.allowNoCommunityId) return null;

      const currentQueryBuilder = filters || new RequestQueryBuilder();
      const newQueryBuilder = new RequestQueryBuilder();

      const search =
        (currentQueryBuilder.queryObject.s
          ? Object.entries(JSON.parse(currentQueryBuilder.queryObject.s)).map(([key, value]) => ({ [key]: value }))
          : []) || [];

      if (calIds?.[0]?.includes(',')) calIds = calIds[0].split(',');

      const calendarIds = calIds?.length
        ? calIds
        : this.onlyStaffCalendars
        ? (await this.calendarService.getManySecuredCached(this.communityId || '', true))?.map(c => c.id)
        : ['not_a_calendar'];

      const endOfDay = moment().endOf('day').toISOString();
      const startOfDay = moment().startOf('day').toISOString();

      if (calendarIds?.length && this.onlyToday) {
        newQueryBuilder.search({
          $and: [
            ...search,
            { 'calendar.id': { $in: calendarIds } },
            { 'event.startDate': { $lte: endOfDay } },
            { 'event.startDate': { $gte: startOfDay } },
          ],
        });
      } else if (calendarIds?.length) {
        newQueryBuilder.search({ $and: [...search, { 'calendar.id': { $in: calendarIds } }] });
      } else if (this.onlyToday) {
        newQueryBuilder.search({
          $and: [...search, { 'event.startDate': { $lte: endOfDay } }, { 'event.startDate': { $gte: startOfDay } }],
        });
      } else newQueryBuilder.search({ $and: [...search] });

      if (showLinkedBookings) {
        const hasUserId = filters?.queryObject['s']?.split(':')?.[0]?.split('"')?.[1] === 'user.id';
        if (hasUserId) {
          newQueryBuilder.search({
            $or: [...search, { bookedById: filters?.queryObject['s']?.split(':')?.[1].split('"')?.[1] }],
          });
        }
      }

      return await this.bookingSvc.paginate(
        newQueryBuilder,
        this.allowNoCommunityId ? undefined : communityId,
        pagination
      );
    }),
    tap(data => (this.metadata = data?.meta)),
    map(data => data?.items || []),
    map(bookings => {
      bookings = bookings.sort((a, b) => {
        if (a.spotId || b.spotId) this.eventHasRoom = true;

        if (a.event.startDate.getTime() === b.event.startDate.getTime()) {
          if (this.sortAlphabetically) {
            const nameA = a.user.displayName || '';
            const nameB = b.user.displayName || '';
            return nameA.localeCompare(nameB);
          }

          let comp = 0;

          if (a.spotId && b.spotId) {
            const spotA = this.getSpot(a);
            const spotB = this.getSpot(b);
            (spotA?.spotNumber || 0) > (spotB?.spotNumber || 0) ? (comp = 1) : (comp = -1);
          }

          return comp;
        } else {
          return b.event.startDate.getTime() - a.event.startDate.getTime();
        }
      });
      return bookings.map(bkg => {
        const ras = bkg.event.resourceAssignments.filter(ra => ra.resource?.type === ResourceType.PERSON);
        return {
          ...bkg,
          event: {
            ...bkg.event,
            resourceAssignments: ras.length ? [ras[0]] : [],
          },
        };
      });
    }),
    tap(bookings => {
      this.totalCheckInBookings = bookings.filter(booking => booking.status === BookingStatus.CONFIRMED).length;
      if (this.totalCheckInBookings) this.totalBookingsToCheckIn.emit(this.totalCheckInBookings);
    }),
    tap(() => (this.loading = false)),
    shareReplay(1)
  );

  totalBookings$ = this.bookings$.pipe(
    map(bookings => bookings.reduce((acc, booking) => acc + (booking.spotsTaken || 1), 0))
  );

  bookedSpotIds$ = this.bookings$.pipe(
    map(bookings => {
      const bookedSpotIds: string[] = [];
      bookings.forEach(booking => {
        if (booking.spotId) bookedSpotIds.push(booking.spotId);
      });

      return bookedSpotIds;
    })
  );

  async onFilterApplied() {
    if (this.paginator !== undefined) this.paginator.firstPage();
  }

  async confirm(booking: Booking) {
    const dialog = this.matDialog.open(SimpleDialog, {
      data: {
        showCloseButton: false,
        title: 'Confirm Booking',
        subtitle: 'Are you sure you want to confirm this booking?',
        buttons: [
          { label: 'Cancel', role: 'no' },
          { label: 'Confirm', role: 'yes' },
        ],
      },
    });

    if ((await toPromise(dialog.afterClosed())) === 'yes') {
      try {
        await this.bookingSvc.confirmPending(booking.id);
        this.snacks.open('Booking Confirmed', 'Ok!', { duration: 2500, panelClass: 'mat-primary' });
        this._filters$.next(this._filters$.value);
      } catch (err: any) {
        this.snacks.open(err?.error?.message || err.message, 'Ok!', { duration: 10000, panelClass: 'mat-primary' });
      }
    }
  }

  toggleBookingToCheckIn(booking: Booking) {
    const index = this.bookingsToCheckIn.indexOf(booking.id);
    if (index >= 0) this.bookingsToCheckIn.splice(index, 1);
    else this.bookingsToCheckIn.push(booking.id);

    this.bookingsToCheckInChange.emit(this.bookingsToCheckIn);
  }

  async confirmWithNewBookingOption(booking: Booking) {
    const dialog = this.matDialog.open(AddBookingOptionDialog, {
      data: { event: booking.event, user: booking.user, booking },
      width: '750px',
      maxWidth: '90%',
    });

    if ((await toPromise(dialog?.afterClosed())) === 'yes') {
      try {
        this._filters$.next(this._filters$.value);
      } catch (err: any) {
        this.snacks.open(err?.error?.message || err.message, 'Ok!', { duration: 10000, panelClass: 'mat-primary' });
      }
    }
  }

  async cancel(booking: Booking, freeOfCharge: boolean) {
    const dialog = this.matDialog.open(SimpleDialog, {
      data: {
        showCloseButton: false,
        title: 'Cancel Booking',
        subtitle: 'Are you sure you want to cancel this booking?',
        content: freeOfCharge
          ? null
          : `
            <p><strong>The following cancellation policy applies</strong>:</p>
            ${getCancellationPolicyInnerHtml(booking.bookingOption)}
          `,
        buttons: [
          { label: 'No, Keep the Booking', role: 'no' },
          { label: 'Yes, Cancel the Booking', role: 'yes' },
        ],
      },
    });

    if ((await toPromise(dialog.afterClosed())) === 'yes') {
      await this.bookingSvc.cancel(booking.id, freeOfCharge);
      this.snacks.open('Booking Cancelled', 'Ok!', { duration: 2500, panelClass: 'mat-primary' });
      this._filters$.next(this._filters$.value);
    }
  }

  async noShow(booking: Booking) {
    const dialog = this.matDialog.open(SimpleDialog, {
      data: {
        showCloseButton: false,
        title: 'Mark as No-Show',
        subtitle: 'Are you sure you want to mark this booking as no-show?',
        content: `
            <p><strong>The following cancellation policy applies</strong>:</p>
            ${getCancellationPolicyInnerHtml(booking.bookingOption)}
          `,
        buttons: [
          { label: 'No, Keep the Booking', role: 'no' },
          { label: 'Yes, No-Show the Booking', role: 'yes' },
        ],
      },
    });

    if ((await toPromise(dialog.afterClosed())) === 'yes') {
      await this.bookingSvc.noShow(booking.id);
      this.snacks.open('Booking No-Show', 'Ok!', { duration: 2500, panelClass: 'mat-primary' });
      this._filters$.next(this._filters$.value);
    }
  }

  async checkIn(booking: Booking) {
    await this.bookingSvc.checkIn(booking.id);
    this.snacks.open('User Checked-In!', 'Ok!', { duration: 2500, panelClass: 'mat-primary' });
    this._filters$.next(this._filters$.value);
  }

  async undoCheckIn(booking: Booking) {
    await this.bookingSvc.undoCheckIn(booking.id);
    this.snacks.open('User Returned to Confirmed!', 'Ok!', { duration: 2500, panelClass: 'mat-primary' });
    this._filters$.next(this._filters$.value);
  }

  async undoNoShow(booking: Booking, hours: number) {
    await this.bookingSvc.undoNoShow(booking.id, hours);
    this.snacks.open('No show reversed!', 'Ok!', { duration: 2500, panelClass: 'mat-primary' });
    this._filters$.next(this._filters$.value);
  }

  async updateSpot(booking: Booking, newSpot: RoomResourceSpot, bookings: Booking[], alreadyBooked = false) {
    let content = '';
    let booking2: Booking | null = null;
    const oldSpot = this.getSpot(booking) || null;
    let buttons = [
      { label: `No, Don't Switch`, role: 'no' },
      { label: `Yes, Switch the Spots`, role: 'yes' },
    ];
    if (bookings && alreadyBooked) {
      booking2 = bookings.find(booking => booking.spotId === newSpot.id) || null;

      if (booking2) {
        if (oldSpot) {
          content =
            (booking.user.friendlyName || booking.user.displayName) +
            ` in spot: '` +
            oldSpot?.name +
            `' will be switched with ` +
            (booking2.user.friendlyName || booking2.user.displayName) +
            ` in spot: '` +
            newSpot?.name +
            `'.<br/><br/>Please make sure both attendees have consented to this switch before taking action`;
        } else {
          content = 'Cannot switch an unassigned spot with a booked spot!';
          buttons = [{ label: `Cancel`, role: 'no' }];
        }
      }
    } else {
      if (oldSpot) content = `The spot '` + oldSpot?.name + `' will be switched to '` + newSpot.name + `'`;
      else content = `User will be assigned to ${newSpot.name}`;
    }

    const dialog = this.matDialog.open(SimpleDialog, {
      data: {
        showCloseButton: false,
        title: 'Switch Booking Spot' + (alreadyBooked ? 's' : ''),
        subtitle: 'Are you sure you want to update the spot assignment' + (alreadyBooked ? 's' : '') + '?',
        content,
        buttons,
      },
    });

    if ((await toPromise(dialog.afterClosed())) === 'yes') {
      await this.bookingSvc.updateSpot(booking.id, newSpot.id);

      if (booking2 && oldSpot) await this.bookingSvc.updateSpot(booking2.id, oldSpot.id);

      this.snacks.open('Spots switched!', 'Ok!', { duration: 2500, panelClass: 'mat-primary' });
      this._filters$.next(this._filters$.value);
    }
  }

  previewFormResponse(response: FormResponse, formTitle: string) {
    this.matDialog.open(ResponsePreviewDialog, { data: { response, formTitle } });
  }

  getColor(status: string) {
    if (status === 'CANCELLED' || status === 'NO-SHOW' || status === 'LATE_CANCELLED') {
      return 'var(--warn-color)';
    } else if (status === 'CHECKED_IN') {
      return 'var(--primary-color)';
    }
    return '';
  }

  getRoom() {
    const room = this.event?.resourceAssignments?.find(assignment => assignment?.resource?.type === ResourceType.ROOM)
      ?.resource as RoomResource;

    if (room && room?.spots) {
      room.spots = room.spots.sort((a, b) => (a.spotNumber > b.spotNumber ? 1 : -1));
    }

    return room;
  }

  getSpot(booking: Booking) {
    return booking?.spot;
  }

  bookingIncludesResource(booking: Booking, userId?: string | null) {
    return booking.event?.resourceAssignments.some(assignment => assignment.resource?.groupId === userId);
  }

  ngOnDestroy() {
    this._filters$.complete();
    this.pagination$.complete();
  }
}
