import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { toPromise } from '@greco-fit/util';
import { AccountResource, AccountResourceAction } from '@greco/finance-accounts';
import { Contact, ContactResource, ContactResourceAction } from '@greco/identity-contacts';
import { UserResource, UserResourceAction } from '@greco/identity-users';
import { ClipboardService } from '@greco/ngx-clipboard-util';
import { InvoiceService } from '@greco/ngx-finance-invoices';
import { UserService } from '@greco/ngx-identity-auth';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
import { MailService } from '@greco/util-ngx-mail';
import { BehaviorSubject } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { CreateMemberNumberDialog } from '../../dialogs/create-member-number/create-member-number.component';
import { ContactService } from '../../services';

@Component({
  selector: 'greco-contact-page',
  templateUrl: './contact.page.html',
  styleUrls: ['./contact.page.scss'],
})
export class ContactPage implements OnChanges {
  constructor(
    private formBuilder: FormBuilder,
    private contactSvc: ContactService,
    private snacks: MatSnackBar,
    private dialog: MatDialog,
    private userSvc: UserService,
    private clipboardSvc: ClipboardService,
    private invoiceSvc: InvoiceService,
    private securitySvc: SecurityService,
    private breakpoints: BreakpointObserver,
    private mailSvc: MailService
  ) {}

  @PropertyListener('contact') _contact$ = new BehaviorSubject<Contact | null>(null);
  @Input() contact!: Contact;
  @Output() updated = new EventEmitter();

  mobile = false;
  isMobile$ = this.breakpoints.observe('(max-width: 1000px)').pipe(
    map(({ matches }) => matches),
    tap(matches => (this.mobile = matches)),
    shareReplay(1)
  );

  emailBlacklisted$ = this._contact$.pipe(
    switchMap(async contact => {
      if (!contact) return null;
      return await this.mailSvc.getBlacklist(contact.email);
    })
  );

  canManageContact$ = this.securitySvc.hasAccess(ContactResource.key, ContactResourceAction.UPDATE, {}, true);
  canManageAuth$ = this.securitySvc.hasAccess(UserResource.key, UserResourceAction.MANAGE_AUTH, {}, true);
  isSuper$ = this.securitySvc.hasAccess(AccountResource.key, AccountResourceAction.CREATE, {}, true);

  resetValue = { displayName: '', email: '', phoneNumber: '' };

  formGroup = this.formBuilder.group({
    displayName: ['', Validators.required],
    email: ['', Validators.required],
    phoneNumber: [''],
  });

  loadingResetLink = false;
  exporting = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.contact.previousValue !== changes.contact.currentValue) {
      this.reset();
    }
  }

  private reset() {
    this.resetValue = {
      displayName: this.contact?.displayName || '',
      email: this.contact?.email || '',
      phoneNumber: this.contact?.phoneNumber || '',
    };

    this.formGroup.reset(this.resetValue);
  }

  async openDialog() {
    const dialog = this.dialog.open(CreateMemberNumberDialog, {
      data: { contact: this.contact, buttons: [] },
    });
    const contact: Contact = await toPromise(dialog.afterClosed());
    if (contact?.id) {
      this.updated.emit(contact);
      this.refresh();
    }
  }

  save = async () => {
    try {
      this.contact = await this.contactSvc.updateContact(this.contact.id, this.formGroup.value);
      this.reset();

      this.snacks.open('Contact updated!', 'Ok', { duration: 2000 });
    } catch (err) {
      console.error(err);
    }
  };

  async refresh() {
    if (this.contact?.id) {
      this.contact = await this.contactSvc.getContact(this.contact.id);
    }
  }

  async verifyEmail() {
    if (!this.contact.user) return;

    this.contact.user = await this.userSvc.verifyEmail(this.contact.user.id);
    this.refresh();

    this.snacks.open('Email verified!', 'Ok', { duration: 2000, panelClass: 'mat-primary' });
  }

  async generateResetLink() {
    if (!this.contact.user) return;

    this.loadingResetLink = true;

    try {
      const { url } = await this.userSvc.getPasswordResetLink(this.contact.user.id);
      this.clipboardSvc.copy(url, 'Reset link');
    } catch (err) {
      console.error(err);
    }

    this.loadingResetLink = false;
  }

  async sendResetEmail() {
    if (!this.contact.user) return;
    await this.userSvc.sendResetPasswordEmail(this.contact?.user?.email || '');
    this.snacks.open('Email sent!', 'Ok', { duration: 2000 });
  }

  async removeBlacklist(email: string) {
    await this.mailSvc.removeFromBlacklist(email);
    this.refresh();
  }
}
