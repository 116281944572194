import { Pipe, PipeTransform } from '@angular/core';
import { Purchase } from '@greco/sales-purchases';

@Pipe({ name: 'purchaseTax' })
export class PurchaseTaxPipe implements PipeTransform {
  transform(purchase: Purchase): number {
    return purchase.items.reduce(
      (acc, item) =>
        acc +
        (item.taxes?.reduce((acc, tax) => acc + tax.percentage / 100, 0) ||
          purchase.taxes.reduce((acc, tax) => acc + tax.percentage / 100, 0)) *
          item.price *
          item.quantity,
      0
    );
  }
}
