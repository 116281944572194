import * as moment from 'moment';
import { BookingOption, CalendarEvent, EventSeries } from '../models';

function formatMinutes(minutes: number) {
  const hours = minutes / 60;
  const days = hours / 24;

  if (days % 1 === 0) return days + ' Day' + (!days || days > 1 ? 's' : '');

  if (hours % 1 === 0) return hours + ' Hour' + (!hours || hours > 1 ? 's' : '');

  return minutes + ' Minute' + (!minutes || minutes > 1 ? 's' : '');
}

export function getCancellationPolicyInnerHtml(bookingOption: BookingOption, late?: boolean): string {
  let html = '';

  if (late) {
    html += `<p>You are canceling within the late cancellation period, which means a <strong>$${(
      bookingOption.cancellationPrice / 100
    ).toFixed(2)} + tax</strong> fee will apply, and/ or you will lose your perk for this session.`;
  } else {
    html += `<p>Please confirm you would like to cancel your booking.</p>`;
  }

  return html;
}

export function calculateBoosterRequirements(
  event: CalendarEvent | EventSeries,
  bookingOption: BookingOption,
  date?: Date
) {
  const now = date ? moment(date) : moment();
  const cutoff = moment(event.startDate).subtract(bookingOption.bookingWindow, 'minutes');
  return now.isBefore(cutoff) ? Math.ceil(cutoff.diff(now, 'days', true)) : 0;
}

export function bookingOptionCancellationPolicyLabel(bookingOption: BookingOption): string {
  if (!bookingOption.cancellation) return 'Anytime, no penalty';

  let label = '';

  if (bookingOption.cancellationWindow) {
    label += formatMinutes(bookingOption.cancellationWindow) + ' Before Event';
  }

  if (bookingOption.cancellationPrice) {
    if (label) label += ' or ';
    label += '$' + (bookingOption.cancellationPrice / 100).toFixed(2) + ' Fee';
  }

  if (!bookingOption.refund) {
    label += label ? ' (No Refund)' : 'No Refund';
  }

  return label;
}

export function isAvailableNow(
  event: CalendarEvent | EventSeries,
  bookingOption: BookingOption,
  now: Date = new Date()
): boolean {
  const mNow = moment(now);

  const bookingWindowReached = mNow.isSameOrAfter(
    moment(event.startDate).subtract(bookingOption.bookingWindow, 'minutes')
  );
  if (bookingWindowReached) return true;
  if (bookingOption.maxBoost === -1) return false;
  if (!bookingOption.maxBoost) return true;

  const boostDays = calculateBoosterRequirements(event, bookingOption, now);
  return bookingOption.maxBoost >= boostDays;
}
