import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogData } from '@greco-fit/scaffolding';
import { toPromise } from '@greco-fit/util';
import { Tax } from '@greco/finance-tax';
import { UpdateVariantDto } from '@greco/nestjs-sales-products';
import { PropertyListener } from '@greco/property-listener-util';
import { Perk } from '@greco/sales-perks';
import {
  Product,
  ProductOption,
  ProductStatus,
  ProductVariant,
  ProductVariantVisibility,
  RecurrencePeriod,
  VariantOption,
  VariantPerk,
  VariantPerkExpiryEvent,
  VariantPerkGranted,
} from '@greco/sales-products';
import { CollapsibleSectionController } from '@greco/ui-collapsible-section';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { PeriodValidator } from '../../components';
import { VariantTransfersTableComponent } from '../../components/variant-transfers-table/variant-transfers-table.component';
import { AddVariantPerkDialog, CreateVariantTransferDialog, UpdateVariantPerkDialog } from '../../dialogs';
import { ProductsService, VariantsService } from '../../services';

@Component({
  selector: 'greco-variant-page',
  templateUrl: './variant.page.html',
  styleUrls: ['./variant.page.scss'],
  viewProviders: [CollapsibleSectionController],
})
export class VariantPage implements OnInit, OnChanges {
  constructor(
    private dialog: MatDialog,
    private snacks: MatSnackBar,
    private formBuilder: FormBuilder,
    private productsService: ProductsService,
    private variantSvc: VariantsService
  ) {}

  @ViewChild(VariantTransfersTableComponent) transfersTable?: VariantTransfersTableComponent;

  @PropertyListener('variant') private variant$ = new BehaviorSubject<ProductVariant | null>(null);
  @Input() variant!: ProductVariant;
  product!: Product;
  productOptions!: ProductOption[];
  perks!: Perk[];

  formDetails = this.formBuilder.group({
    title: [''],
    priority: [0],
    image: [null],
    visibility: [ProductVariantVisibility.VISIBLE],
  });
  controlsConfigDetails: { [key: string]: any } = {};
  resetValueDetails: any = {};
  processingDetails = false;

  formPrice = this.formBuilder.group({ variantPrice: [null, Validators.required], prorateStart: [null] });
  resetValuePrice: any = {};
  processingPrice = false;
  processingMinimumCommitment = false;

  minimalDate = moment().add(1, 'days').toDate();
  maximalDate = moment().add(5, 'years').toDate();

  isValidForProrateStart = false;

  resetMinimumCommitmentValue: any = {};
  minimumCommitmentForm = this.formBuilder.group({
    commitmentLength: [0, [Validators.min(1)]],
    commitmentPeriod: [RecurrencePeriod.Monthly, [Validators.required, PeriodValidator]],
  });

  variantPerks$ = this.variant$.pipe(
    map(variant => variant?.variantPerks.sort((a, b) => a.perk.title.localeCompare(b.perk.title)))
  );

  async ngOnInit() {
    this.product = await this.productsService.getOneProduct(this.variant.productId);

    this.productOptions = (await this.productsService.paginateProductOptions(1, 10, this.variant.productId)).items;
    this.productOptions.forEach(productOption => {
      const variantOption = this.findVariantOption(productOption.id);
      this.controlsConfigDetails[`${productOption.id}`] = [variantOption?.value || '', Validators.required];
      this.resetValueDetails[`${productOption.id}`] = variantOption?.value || '';
      this.formDetails.addControl(
        productOption.id,
        this.formBuilder.control(variantOption?.value || '', Validators.required)
      );
    });

    this.resetValueDetails.title = this.variant?.title || '';
    this.resetValueDetails.priority = this.variant?.priority || 0;
    this.resetValueDetails.image = this.variant?.image || null;
    this.resetValueDetails.visibility = this.variant?.visibility || ProductVariantVisibility.VISIBLE;

    this.formDetails.reset({
      ...this.formDetails.value,
      title: this.variant?.title || '',
      priority: this.variant?.priority || 0,
      image: this.variant?.image || null,
      visibility: this.variant?.visibility || ProductVariantVisibility.VISIBLE,
    });

    this.isValidForProrateStart =
      this.variant?.recurrence?.cycles === null && this.variant?.recurrence?.period === 'WEEKLY' ? true : false;

    this.formPrice = this.formBuilder.group({
      variantPrice: [
        {
          price: this.variant.price,
          recurrence: this.variant.recurrence,
          minimumCommitment: this.variant.minimumCommitment,
          paymentTypes: this.variant.paymentTypes,
          autoUpgradeVariant: this.variant.autoUpgradeVariant,
          enrolmentFee: this.variant?.enrolmentFee || null,
          enrolmentFeeName: this.variant?.enrolmentFeeName || null,
          enrolmentFeeDescription: this.variant?.enrolmentFeeDescription || null,
          enrolmentFeeSaleCategory: this.variant?.enrolmentFeeSaleCategory || null,
          taxes: this.variant?.taxes ? this.variant.taxes : [],
          ignoreTaxes: !!this.variant?.ignoreTaxes,
        },
        Validators.required,
      ],
      prorateStart: this.variant?.prorateStart && this.isValidForProrateStart ? this.variant?.prorateStart : null,
    });

    this.resetValuePrice = {
      variantPrice: {
        price: this.variant.price,
        recurrence: this.variant.recurrence,
        minimumCommitment: this.variant.minimumCommitment,
        paymentTypes: this.variant.paymentTypes,
        autoUpgradeVariant: this.variant.autoUpgradeVariant,
        enrolmentFee: this.variant?.enrolmentFee || null,
        enrolmentFeeName: this.variant?.enrolmentFeeName || null,
        enrolmentFeeDescription: this.variant?.enrolmentFeeDescription || null,
        enrolmentFeeSaleCategory: this.variant?.enrolmentFeeSaleCategory || null,
        taxes: this.variant?.taxes ? this.variant.taxes : [],
        ignoreTaxes: !!this.variant?.ignoreTaxes,
      },
    };

    this.resetMinimumCommitmentValue = {
      commitmentLength: this.variant.minimumCommitment?.length,
      commitmentPeriod: this.variant.minimumCommitment?.period || RecurrencePeriod.Monthly,
    };

    this.minimumCommitmentForm.patchValue(this.resetMinimumCommitmentValue);

    if (this.variant) this.variant.variantPerks.sort((a, b) => a.perk.title.localeCompare(b.perk.title));

    this.onChange();
  }

  onChange() {
    this.formPrice.get('variantPrice')?.valueChanges.subscribe(x => {
      //isValid only if indefinite && weekly
      this.isValidForProrateStart = x?.recurrence?.cycles === null && x?.recurrence?.period === 'WEEKLY' ? true : false;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.user?.previousValue !== changes.user?.currentValue) this.resetDetails();
  }

  saveDetails = async () => {
    this.processingDetails = true;

    try {
      if (this.processingPrice) throw new Error('Please wait until the variant price is saved!');

      const data: UpdateVariantDto = {};
      const variantOpts: any[] = [];

      this.productOptions.forEach(productOption => {
        variantOpts.push({
          productOptionId: productOption.id,
          value: eval(`this.formDetails.value.${productOption.id}`),
        });
      });

      if (variantOpts.length) data.variantOpts = variantOpts;
      if (this.formDetails.value?.title !== this.variant.title) data.title = this.formDetails.value?.title;
      if (this.formDetails.value?.priority !== this.variant.priority) data.priority = this.formDetails.value?.priority;
      if (this.formDetails.value?.image?.id !== this.variant.image?.id) {
        if (!this.formDetails.value?.image?.id) data.imageId = 'remove';
        else data.imageId = this.formDetails.value?.image.id;
      }
      if (this.formDetails.value?.visibility !== this.variant.visibility) {
        data.visibility = this.formDetails.value?.visibility;
      }
      if (this.minimumCommitmentForm.value.commitmentLength) {
        data.minimumCommitment = {
          length: this.minimumCommitmentForm.value.commitmentLength,
          period: this.minimumCommitmentForm.value.commitmentPeriod,
        };
      }

      if (!Object.keys(data).length) throw new Error("You haven't made any changes");

      this.variant = await this.variantSvc.updateVariant({
        ...(this.variant.status === ProductStatus.Draft ? { price: this.formPrice?.value.variantPrice } : {}),
        productId: this.variant.productId,
        variantId: this.variant.id,
        data,
      });

      this.formDetails.markAsPristine();

      this.snacks.open('Changes saved!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
    } catch (err: any) {
      this.snacks.open(err, 'Ok', { duration: 2500, panelClass: 'mat-warn' });
      console.error(err);
    }

    this.processingDetails = false;
  };

  private resetDetails() {
    this.productOptions.forEach(productOption => {
      const variantOption = this.findVariantOption(productOption.id);

      this.resetValueDetails[`${productOption.id}`] = variantOption?.value || '';
    });
    this.resetValueDetails.title = this.variant.title;
    this.resetValueDetails.image = this.variant?.image || null;
    this.formDetails.reset(this.resetValueDetails);
    this.formDetails.markAsPristine();
  }

  savePrice = async () => {
    this.processingPrice = true;

    try {
      if (this.processingDetails) throw new Error('Please wait until the variant details are saved!');

      const formData = this.formPrice.value.variantPrice;
      const prorateStart = this.formPrice.value.prorateStart;

      const data: UpdateVariantDto = {
        paymentTypes: formData.paymentTypes,
        enrolmentFee: undefined,
        enrolmentFeeName: null,
        enrolmentFeeDescription: null,
        enrolmentFeeSalesCategoryId: null,
        prorateStart: null,
      };

      if (formData.price !== this.variant.price) data.price = Math.round(formData.price);

      if (!formData.recurrence && this.variant.recurrence) {
        data.recurrence = null;
        data.autoUpgradeVariantId = null;
      }
      if (formData.recurrence) {
        data.recurrence = {
          frequency: formData.recurrence.frequency,
          period: formData.recurrence.period,
          cycles: formData.recurrence.cycles || null,
        };

        data.minimumCommitment = {
          length: this.minimumCommitmentForm.value.commitmentLength,
          period: this.minimumCommitmentForm.value.commitmentPeriod,
        };

        data.autoUpgradeVariantId = formData.autoUpgradeVariantId;
        data.enrolmentFee = formData.enrolmentFeeEnabled ? formData.enrolmentFee ?? null : null;
        data.enrolmentFeeName = formData.enrolmentFeeEnabled ? formData.enrolmentFeeName || null : null;
        data.enrolmentFeeDescription = formData.enrolmentFeeEnabled ? formData.enrolmentFeeDescription || null : null;
        data.enrolmentFeeSalesCategoryId = formData.enrolmentFeeSaleCategory
          ? formData.enrolmentFeeSaleCategory?.id || null
          : null;
        data.prorateStart = prorateStart && this.isValidForProrateStart ? prorateStart : null;
      }

      if (formData.taxes !== this.variant.taxes) data.taxes = formData.taxes?.map((tax: Tax) => tax.id) || [];
      if (formData.ignoreTaxes !== this.variant.ignoreTaxes) data.ignoreTaxes = formData.ignoreTaxes || false;

      if (!Object.keys(data).length) throw new Error("You haven't made any changes");
      this.variant = await this.variantSvc.updateVariant({
        productId: this.variant.productId,
        variantId: this.variant.id,
        data,
      });
      this.resetPrice();

      this.snacks.open('Changes saved!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
    } catch (err: any) {
      this.snacks.open(err, 'Ok', { duration: 2500, panelClass: 'mat-warn' });
      console.error(err);
    }

    this.processingPrice = false;
  };

  private resetPrice() {
    this.resetValuePrice = {
      variantPrice: {
        price: this.variant.price,
        recurrence: this.variant.recurrence,
        minimumCommitment: this.variant.minimumCommitment,
        paymentTypes: this.variant.paymentTypes,
        autoUpgradeVariant: this.variant.autoUpgradeVariant,
        enrolmentFee: this.variant?.enrolmentFee ?? null,
        enrolmentFeeName: this.variant?.enrolmentFeeName ?? null,
        enrolmentFeeDescription: this.variant?.enrolmentFeeDescription ?? null,
        enrolmentFeeSalesCategory: this.variant?.enrolmentFeeSaleCategory ?? null,
        enrolmentFeeEnabled: this.variant?.enrolmentFee != null || this.variant?.enrolmentFeeName != null,
        taxes: this.variant.taxes || [],
        ignoreTaxes: this.variant.ignoreTaxes,
      },
      prorateStart: this.variant?.prorateStart && this.isValidForProrateStart ? this.variant?.prorateStart : null,
    };
    this.formPrice.reset(this.resetValuePrice);
    this.formPrice.markAsPristine();
  }

  saveMinimumCommitment = async () => {
    this.processingMinimumCommitment = true;

    try {
      if (this.processingDetails) throw new Error('Please wait until the variant details are saved!');

      const formData = this.minimumCommitmentForm.value;

      const data: UpdateVariantDto = {
        minimumCommitment: {
          length: formData.commitmentLength,
          period: formData.commitmentPeriod,
        },
      };

      this.variant = await this.variantSvc.updateVariant({
        productId: this.variant.productId,
        variantId: this.variant.id,
        data,
      });
      this.resetMinimumCommitment();

      this.snacks.open('Changes saved!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
    } catch (err: any) {
      this.snacks.open(err, 'Ok', { duration: 2500, panelClass: 'mat-warn' });
      console.error(err);
    }

    this.processingMinimumCommitment = false;
  };

  resetMinimumCommitment() {
    this.resetMinimumCommitmentValue = {
      commitmentLength: this.variant.minimumCommitment?.length,
      commitmentPeriod: this.variant.minimumCommitment?.period,
    };
    this.minimumCommitmentForm.reset(this.resetMinimumCommitmentValue);
    this.minimumCommitmentForm.markAsPristine();
  }

  async addVariantPerk() {
    const dialog = this.dialog.open(AddVariantPerkDialog, {
      data: { product: this.product, variant: this.variant },
      width: '750px',
      maxWidth: '90%',
    });

    const result = await toPromise(dialog.afterClosed());
    if (result) {
      this.variant = await this.variantSvc.getOneVariant({ productId: this.product.id, variantId: this.variant.id });
    }
  }

  async removeVariantPerk(variantPerk: VariantPerk) {
    const confirmation = await toPromise(
      this.dialog
        .open(SimpleDialog, {
          data: {
            title: 'Confirmation',
            content: `
              <p>
                You are about to remove the variant perk <strong>${variantPerk.perk.title}</strong>
                with id <strong>${variantPerk.id}</strong>. This action is not reversible.
              </p>
              <br>
              <p>
                Are you sure you want to continue?
              </p>
            `,
            buttons: [
              { label: 'Cancel', role: 'cancel' },
              { label: 'Confirm', role: 'confirm' },
            ],
          },
        })
        .afterClosed()
    );

    if (confirmation === 'confirm') {
      try {
        await this.variantSvc.removeVariantPerk({
          productId: this.product.id,
          variantId: this.variant.id,
          variantPerkId: variantPerk.id,
        });
        this.variant = await this.variantSvc.getOneVariant({ productId: this.product.id, variantId: this.variant.id });
        this.snacks.open('Removed!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
      } catch (err: any) {
        this.snacks.open(err, 'Ok', { duration: 2500, panelClass: 'mat-warn' });
        console.error(err);
      }
    }
  }

  async updateVariantPerk(variantPerk: VariantPerk) {
    const dialog = await toPromise(
      this.dialog
        .open(UpdateVariantPerkDialog, { data: { product: this.product, variant: this.variant, variantPerk } })
        .afterClosed()
    );
    if (dialog) {
      this.variant = await this.variantSvc.getOneVariant({
        variantId: this.variant.id,
        productId: this.product.id,
      });
      this.snacks.open('Variant Perk Updated!', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
    }
  }

  private findVariantOption(productOptionId: string): VariantOption | undefined {
    for (let i = 0; i < this.variant.variantOpts?.length; i++) {
      const variantOption = this.variant.variantOpts[i];
      if (variantOption.optionId === productOptionId) return variantOption;
    }

    return undefined;
  }

  async activate(variantPerk: VariantPerk) {
    if (this.variant.recurrence && variantPerk.granted.includes(VariantPerkGranted.Initial)) {
      const count = await this.variantSvc.getProductVariantSubscriptionCount(
        this.product.id,
        this.variant.id,
        variantPerk.id
      );

      const data: DialogData = {
        title: 'Activate Perk',
        content: `
          <p>Once activated, this perk will be granted at the time of the initial purchase of all users who subscribe to this product variant.</p>
          <p>There are currently ${count} users actively subscribed to this product variant.</p>
          <p>Should this perk be retroactively be granted to these actively subscribed users?</p>
        `,
        buttons: [
          { label: 'Cancel', role: 'cancel' },
          { label: "Activate, but don't grant perk", role: 'no' },
          { label: 'Activate and grant perk', role: 'yes' },
        ],
      };

      const dialog = this.dialog.open(SimpleDialog, { data });
      const result = await toPromise(dialog.afterClosed());

      if (result === 'yes' || result === 'no') {
        await this.variantSvc.activateVariantPerk({
          retroactivelyGrant: result === 'yes',
          variantPerkId: variantPerk.id,
          productId: this.product.id,
          variantId: this.variant.id,
        });
      }
    } else {
      await this.variantSvc.activateVariantPerk({
        variantPerkId: variantPerk.id,
        productId: this.product.id,
        variantId: this.variant.id,
      });
    }

    this.variant = await this.variantSvc.getOneVariant({ productId: this.product.id, variantId: this.variant.id });
  }

  async archive(variantPerk: VariantPerk) {
    const count = await this.variantSvc.getProductVariantSubscriptionCount(
      this.product.id,
      this.variant.id,
      variantPerk.id
    );

    const data: DialogData = {
      title: 'Archive Perk Confirmation',
      content: `
        <p>Once archived, this perk will no longer be available to be granted to users unless you reactivate it.</p>
        <p>There are currently ${count} user(s) actively subscribed to this product variant.</p>
        <p>Do you want to continue?</p>
      `,
      buttons: [
        { label: 'Cancel', role: 'cancel' },
        { label: 'Archive Perk', role: 'yes' },
        ...(!variantPerk.expiryEvent.includes(VariantPerkExpiryEvent.RENEWAL)
          ? [{ label: 'Archive and remove on next billing cycle', role: 'remove_next_renewal' }]
          : []),
      ],
    };

    const dialog = this.dialog.open(SimpleDialog, { data });
    const result = await toPromise(dialog.afterClosed());

    if (result === 'yes' || result === 'remove_next_renewal') {
      if (result === 'remove_next_renewal') {
        await this.variantSvc.updateVariantPerk({
          variantPerkId: variantPerk.id,
          productId: this.product.id,
          variantId: this.variant.id,
          data: {
            expiryEvent: [...new Set([...variantPerk.expiryEvent, VariantPerkExpiryEvent.RENEWAL])],
          },
        });
      }

      await this.variantSvc.archiveVariantPerk({
        variantPerkId: variantPerk.id,
        productId: this.product.id,
        variantId: this.variant.id,
      });
    }

    this.variant = await this.variantSvc.getOneVariant({ productId: this.product.id, variantId: this.variant.id });
  }

  async createVariantTransfer(event: MouseEvent) {
    event.stopImmediatePropagation();
    const dialog = this.dialog.open(CreateVariantTransferDialog, {
      data: { product: this.product, variant: this.variant },
      width: '750px',
      maxWidth: '90%',
    });

    const result = await toPromise(dialog.afterClosed());
    if (result) this.transfersTable?.refresh();
  }
}
