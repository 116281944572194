import { Component, Injectable, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { toPromise } from '@greco-fit/util';
import {
  EventResourceSecurityResource,
  EventResourceSecurityResourceAction,
  EventResourceTagSecurityResource,
  EventResourceTagSecurityResourceAction,
  Resource,
  ResourceType,
} from '@greco/booking-events';
import { Community } from '@greco/identity-communities';
import { ContactResource, ContactResourceAction } from '@greco/identity-contacts';
import { BuildSearchFilter, BuildTextFilter } from '@greco/ngx-filters';
import { BreadcrumbService } from '@greco/ngx-routes-util';
import { SecurityService } from '@greco/ngx-security-util';
import { CondOperator, RequestQueryBuilder } from '@nestjsx/crud-request';
import type { IPaginationMeta } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { CreateResourceDialog, ManageTagsDialog } from '../../../dialogs';
import { ResourcesService } from '../../../services';
import { EventsPage } from '../../events/events.page';
import { TagsSelectFilter } from '../../events/filters';

@Injectable({ providedIn: 'any' })
export class ResourceTitleFilter extends BuildTextFilter('ResourceTitleFilter', {
  label: 'Display Name',
  shortLabel: 'Display Name',
  description: '',
  allowedOperators: [CondOperator.CONTAINS_LOW, CondOperator.EQUALS_LOW],
  properties: ['name'],
}) {}

@Injectable({ providedIn: 'any' })
export class ResourceSearchFilter extends BuildSearchFilter('ResourceSearchFilter', {
  properties: ['name', 'description'],
  propertyLabels: ['Display Name', 'Description'],
}) {}

@Component({
  selector: 'greco-trainer-resources-page',
  templateUrl: './trainer-resources.page.html',
  styleUrls: ['./trainer-resources.page.scss'],
})
export class TrainerResourcesPage implements OnInit, OnChanges {
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private tagsFilter: TagsSelectFilter,
    private securitySvc: SecurityService,
    private resourceSvc: ResourcesService,
    private crumbService: BreadcrumbService
  ) {}

  private _community$ = new BehaviorSubject<Community | null>(null);

  @Input() get community() {
    return this._community$.value;
  }
  set community(community) {
    this._community$.next(community);
    this.tagsFilter.communityId = community?.id;
  }
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  canManageTags$ = this._community$.pipe(
    switchMap(async community => {
      return community
        ? await this.securitySvc.hasAccess(
            EventResourceTagSecurityResource.key,
            EventResourceTagSecurityResourceAction.MANAGE,
            { communityId: community.id }
          )
        : false;
    })
  );

  canCreateResource$ = this._community$.pipe(
    switchMap(async community => {
      if (!community) return false;

      const canReadContacts = await this.securitySvc.hasAccess(ContactResource.key, ContactResourceAction.READ, {
        communityId: community.id,
      });
      if (!canReadContacts) return false;

      return await this.securitySvc.hasAccess(
        EventResourceSecurityResource.key,
        EventResourceSecurityResourceAction.CREATE_TRAINER,
        { communityId: community.id }
      );
    })
  );

  filterOptions = [ResourceSearchFilter, ResourceTitleFilter];
  loading = false;

  filters$ = new BehaviorSubject<RequestQueryBuilder>(new RequestQueryBuilder());
  page$ = new BehaviorSubject<{ page: number; limit: number }>({ page: 1, limit: 10 });

  pagination: null | IPaginationMeta = null;

  resources$ = combineLatest([this._community$, this.filters$, this.page$]).pipe(
    tap(() => (this.loading = true)),
    debounceTime(500),
    switchMap(async ([community, filters, pagination]) =>
      community
        ? await this.resourceSvc.paginateResources(filters, community.id, pagination, ResourceType.PERSON)
        : null
    ),
    tap(data => (this.pagination = data?.meta || null)),
    map(data => data?.items || []),
    map(resources =>
      resources.map(resource => ({
        ...resource,
        resourceTags: resource.resourceTags.sort((a, b) => a.label.localeCompare(b.label)),
      }))
    ),
    tap(() => (this.loading = false))
  );

  async createResource() {
    const communityId = this.community?.id;
    if (!communityId) return;

    const _dialog = this.dialog.open(CreateResourceDialog, {
      data: { communityId, type: ResourceType.PERSON },
      width: '750px',
      maxWidth: '90%',
    });
    await toPromise(_dialog.afterClosed());
  }

  async manageTags() {
    const communityId = this.community?.id;
    if (!communityId) return;

    const _dialog = this.dialog.open(ManageTagsDialog, { data: { communityId }, width: '750px', maxWidth: '90%' });
    await toPromise(_dialog.afterClosed());
    this.refresh();
  }

  openResource(resource: Resource) {
    this.router.navigate([resource.id], { relativeTo: this.route });
  }

  viewInCalendar(resource: Resource) {
    const communityId = this.community?.id;
    if (!communityId) return;

    const url = EventsPage.buildUrlTree(this.router, communityId, this.resourceSvc, resource).toString();
    return this.router.navigateByUrl(url);
  }

  refresh() {
    this.filters$.next(this.filters$.value);
  }

  onFilterApplied() {
    if (this.paginator !== undefined) this.paginator.firstPage();
  }

  ngOnInit(): void {
    setTimeout(() => this.crumbService.set([]));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.community.previousValue !== changes.community.currentValue) {
      this.refresh();
    }
  }
}
