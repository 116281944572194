import { Component, Input } from '@angular/core';
import { AlertConfig, AlertType } from '@greco/ngx-alerts';

@Component({
  selector: 'greco-frozen-subscription-alert',
  templateUrl: './frozen-subscription-alert.component.html',
  styleUrls: ['./frozen-subscription-alert.component.scss'],
})
export class FrozenSubscriptionAlertComponent {
  @Input() isUser = true;

  userAlert: AlertConfig = {
    title: 'Membership Frozen',
    icon: 'warning',
    type: AlertType.DANGER,
    description:
      'As requested, your membership is currently frozen and you will not be able to book into events. To unfreeze and restore access to bookings, please contact our team.',
  };

  staffAlert: AlertConfig = {
    title: 'Membership Frozen',
    type: AlertType.DANGER,
    icon: 'warning',
    description:
      'While a membership is frozen, the member loses access to whatever perks are associated with it and the ability to book into events.',
  };
}
