import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import {
  EventResourceAssignment,
  Resource,
  ResourceAssignment,
  ResourceAssignmentStatus,
  ResourceAvailabilityStatus,
  ResourceTag,
  ResourceType,
} from '@greco/booking-events';
import { PropertyListener } from '@greco/property-listener-util';
import { CondOperator, RequestQueryBuilder } from '@nestjsx/crud-request';
import { BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { EventService, ResourcesService } from '../../services';
import { ResourceTagService } from '../../services/resource-tag.service';

@Component({
  selector: 'greco-event-resource-assignment',
  templateUrl: './event-resource-assignment.component.html',
  styleUrls: ['./event-resource-assignment.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: EventResourceAssignmentComponent }],
})
export class EventResourceAssignmentComponent implements ControlValueAccessor, OnDestroy {
  constructor(
    private eventSvc: EventService,
    private _elementRef: ElementRef,
    private resourceSvc: ResourcesService,
    private resourceTagSvc: ResourceTagService,
    @Optional() @Self() public ngControl: NgControl
  ) {
    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
  }

  private static _id = 1;
  private static _controlType = 'greco-event-resource-assignment-picker';

  private _onTouched?: () => void;
  private _onChange?: (value: EventResourceAssignment[] | null) => void;
  private _onDestroy$ = new Subject<void>();
  readonly stateChanges = new Subject<void>();

  @ViewChild(MatInput) private _input?: MatInput;

  @HostBinding() readonly id = `${
    EventResourceAssignmentComponent._controlType
  }-${EventResourceAssignmentComponent._id++}`;
  readonly controlType = EventResourceAssignmentComponent._controlType;

  @PropertyListener('communityId') private _communityId$ = new BehaviorSubject<string>('');
  @Input() communityId!: string | null;

  @PropertyListener('startDate') private _startDate$ = new BehaviorSubject<ResourceTag[]>([]);
  @Input() startDate?: Date | null;
  @Input() endDate?: Date | null;
  @PropertyListener('duration') private _duration$ = new BehaviorSubject<ResourceTag[]>([]);
  @Input() duration?: number;

  @Input() eventId?: string | null;
  @Input() forSeries?: boolean = false;
  @Input() resource: Resource | null = null;
  @Input() resourceTag: ResourceTag | null = null;

  searchQuery$ = new BehaviorSubject<string>('');

  confirmed = ResourceAssignmentStatus.CONFIRMED;
  pending = ResourceAssignmentStatus.PENDING;
  requestingSubstitution = ResourceAssignmentStatus.REQUESTING_SUBSTITUTION;
  busy = ResourceAvailabilityStatus.BUSY;

  @Output() resourceAvailability = new EventEmitter<{ [key: string]: ResourceAvailabilityStatus }>();
  availability: { [key: string]: ResourceAvailabilityStatus } = {};
  seriesRecurrence: string[] = [];

  emptyIds: string[] = [];
  selectedTag: ResourceTag | null = null;
  selectedAssignment: EventResourceAssignment | null = null;

  filteredResources: Resource[] = [];
  previousTags: ResourceTag[] = [];
  internalSet = false;
  resourcePageSet = true;

  _required = true;
  _readonly = false;

  tags$ = this._communityId$.pipe(
    switchMap(async communityId => {
      if (!communityId) return null;
      return this.resourceTagSvc.getResourceTags(communityId);
    }),
    map(tags => tags?.sort((a, b) => a.label.localeCompare(b.label))),
    shareReplay(1)
  );

  resources$ = combineLatest([this._communityId$, this._startDate$, this._duration$]).pipe(
    switchMap(async ([communityId, _startDate, _duration]) =>
      !communityId
        ? []
        : await this.resourceSvc.getAllCommunityResources(
            RequestQueryBuilder.create({
              search: {
                $and: [
                  { disabled: { [CondOperator.EQUALS]: false } },
                  { type: { [CondOperator.NOT_EQUALS]: ResourceType.ZOOM } },
                ],
              },
            }),
            communityId
          )
    ),
    tap(async resources => {
      const duration = this.duration;
      const startDate = this.startDate;

      if (resources.length && startDate && duration) {
        if (this.forSeries) {
          await Promise.all(
            resources?.map(async resource => {
              this.availability[resource.id] = await this.eventSvc.verifySeriesResourceAvailability(resource.id, {
                seriesRecurrence: this.seriesRecurrence?.join(',') || '',
                endDate: this.endDate ? this.endDate : undefined,
                duration: duration.toString(),
                seriesId: this.eventId || '',
                startDate: startDate,
              });
            })
          );
        } else {
          const busy = await this.eventSvc.getBusyResources(
            startDate,
            new Date(startDate.getTime() + duration * 60000),
            this.eventId ?? undefined
          );

          this.availability = resources.reduce(
            (a, { id, groupId }) => ({
              ...a,
              [id]: busy[groupId] ?? ResourceAvailabilityStatus.AVAILABLE,
            }),
            {}
          );
        }
      } else {
        this.availability = {};
      }

      this.resourceAvailability.emit(this.availability);
    }),
    shareReplay(1)
  );

  tagsAndResources$ = combineLatest([this.searchQuery$, this.tags$, this.resources$, this._communityId$]).pipe(
    map(([searchQuery, tags, resources, _communityId]) => {
      if (!tags || !resources) return null;

      const filtered: { tag: ResourceTag; resources: Resource[] }[] = [];
      resources.forEach(resource => {
        if (resource.name.toLowerCase().includes(searchQuery.toLowerCase())) {
          resource.resourceTags.forEach(tag => {
            if (this.selectedTag && this.selectedTag.id !== tag.id) return;

            const index = filtered.findIndex(grouping => grouping.tag.id === tag.id);

            if (index > -1) filtered[index].resources.push(resource);
            else filtered.push({ tag, resources: [resource] });
          });
        }
      });

      return filtered.sort((a, b) => a.tag.label.localeCompare(b.tag.label));
    }),
    shareReplay(1)
  );

  private _value: EventResourceAssignment[] | null = null;
  @Input() get value() {
    return this._value;
  }
  set value(value: EventResourceAssignment[] | null) {
    this._value = value;

    if (value && value.length && !this.internalSet) {
      this.selected = value;
      this.selectedIds = this.selected.map(
        item => (item.resourceTag?.id || item.resourceTagId || '') + '__' + (item.resource ? item.resource.id : '')
      );
    }

    this.addResourcePageResource();

    this.internalSet = false;
    this._onChange?.(value);
    this.stateChanges.next();
  }

  @Input() get required() {
    return this._required;
  }
  set required(required: boolean) {
    this._required = coerceBooleanProperty(required);
    this.stateChanges.next();
  }

  private _disabled = false;
  @Input() set disabled(disabled: boolean) {
    this._disabled = coerceBooleanProperty(disabled);
    this.stateChanges.next();
  }
  get disabled() {
    return this._disabled;
  }

  get focused() {
    return this._input?.focused || false;
  }

  get empty() {
    return this._input?.empty || true;
  }

  get shouldLabelFloat() {
    return this._input?.focused || !!this._input?.value || (this.value?.length && !this._input?.value) || false;
  }

  get errorState() {
    return (
      !!this.ngControl?.touched &&
      (!!Object.keys(this.ngControl?.errors || {}).length || (this.required && !this.value))
    );
  }

  selected: EventResourceAssignment[] = [];
  selectedIds: string[] = [];

  select(resourceTag: ResourceTag | null, resource: Resource | null) {
    this.selectedIds.includes((resourceTag?.id || '') + '__' + (resource?.id || ''))
      ? this.removeFromSelected(resourceTag?.id || '', resource ? resource.id : '')
      : this.addToSelected(resourceTag, resource);
  }

  addToSelected(resourceTag: ResourceTag | null, resource: Resource | null) {
    if (!this.emptyIds.includes(resource?.id || '')) this.emptyIds.push(resource?.id || '');

    // If an empty assignment is found assign it to this resource
    const index = this.selectedIds.findIndex(id => id === (resourceTag?.id || '') + '__');
    if (index !== -1 && resource && resourceTag) {
      this.selected[index].id = '';
      this.selectedIds[index] += resource.id;
      this.selected[index].resource = resource;
      this.selected[index].resourceId = resource.id;
      this.selected[index].resourceTag = resourceTag;
      this.selected[index].resourceTagId = resourceTag.id;
      this.selected[index].resourceStatus = ResourceAssignmentStatus.CONFIRMED;
    } else {
      if (this.selectedAssignment && this.selectedAssignment.resourceTagId && this.selectedAssignment.resourceId) {
        this.removeFromSelected(this.selectedAssignment.resourceTagId, this.selectedAssignment.resourceId);
        this.selectedAssignment = null;
      }

      // Otherwise assign it normally
      this.selected.push({
        resource: resource || null,
        resourceId: resource?.id || null,
        resourceTag: resourceTag || null,
        resourceTagId: resourceTag?.id || null,
        resourceStatus: resource
          ? ResourceAssignmentStatus.CONFIRMED
          : ResourceAssignmentStatus.REQUESTING_SUBSTITUTION,
      } as EventResourceAssignment);
      this.selectedIds.push((resourceTag?.id || '') + '__' + (resource?.id || ''));
      this.internalSet = true;
    }
    this.value = [...this.selected];
  }

  removeFromSelected(resourceTagId: string, resourceId: string) {
    if (this.emptyIds.includes(resourceId)) this.emptyIds = this.emptyIds.filter(id => id !== resourceId);

    const index = this.selectedIds.findIndex(id => id === resourceTagId + '__' + resourceId);
    this.selected.splice(index, 1);
    this.selectedIds.splice(index, 1);
    this.internalSet = true;
    this.value = [...this.selected];
  }

  updateStatus(assignment: ResourceAssignment, status: ResourceAssignmentStatus) {
    const index = this.selectedIds.findIndex(
      id => id === (assignment?.resourceTagId || '') + '__' + (assignment?.resourceId || '')
    );
    this.selected[index].resourceStatus = status;
    this.internalSet = true;
    this.value = [...this.selected];
  }

  filterPerson(assignments: EventResourceAssignment[]) {
    // assignments = assignments.filter(assignment => assignment?.resourceTagId);

    const noSubNeeded = assignments.filter(
      assignment =>
        assignment.resourceStatus === ResourceAssignmentStatus.REQUESTING_SUBSTITUTION ||
        assignment.resourceStatus === ResourceAssignmentStatus.CONFIRMED
    );
    const subNeeded = assignments.filter(assignment => assignment.resourceStatus === ResourceAssignmentStatus.PENDING);

    subNeeded.sort((a, b) => {
      if (a.created && b.created) {
        return new Date(a.created).getTime() - new Date(b.created).getTime();
      }
      return 0;
    });

    return [...noSubNeeded, ...subNeeded];
  }

  selectTag(resourceTag?: ResourceTag) {
    if (resourceTag) this.selectedTag = resourceTag;
    this.searchQuery$.next(this.searchQuery$.value);
  }

  selectAssignment(resourceAssignment?: EventResourceAssignment) {
    if (resourceAssignment) {
      this.selectedAssignment = resourceAssignment;
      this.selectTag(resourceAssignment.resourceTag);
    }
  }

  addResourcePageResource() {
    if (this.resource && this.resourceTag) {
      if (!this.selectedIds.includes(this.resourceTag.id + '__' + (this.resource ? this.resource.id : ''))) {
        this.select(this.resourceTag, this.resource);
      }
    }
  }

  refreshAvailability(recurrence: string[], startDate: Date) {
    this.seriesRecurrence = recurrence;
    if (startDate) this.startDate = startDate;
    this._communityId$.next(this.communityId || '');
  }

  setDescribedByIds(ids: string[]): void {
    const controlElement = this._elementRef.nativeElement.querySelector('.event-resource-assignment-picker-input');
    controlElement?.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(_: MouseEvent): void {
    this._input?.focus();
  }

  writeValue(value: EventResourceAssignment[]): void {
    this.value = value;
  }

  registerOnChange(fn: (value: EventResourceAssignment[] | null) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  touched() {
    this._onTouched?.();
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
