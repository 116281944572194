import { ClipboardModule as CdkClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { JoinPipeModule } from '@greco-fit/util/ngx';
import { TimezoneModule } from '@greco/feature-timezone';
import { AlertsModule } from '@greco/ngx-alerts';
import { EventsModule } from '@greco/ngx-booking-events';
import { ClipboardModule } from '@greco/ngx-clipboard-util';
import { FiltersModule } from '@greco/ngx-filters';
import { AccountSearchComponentModule } from '@greco/ngx-finance-accounts';
import { PaymentsModule } from '@greco/ngx-finance-payments';
import { GrecoFinanceFeatureTaxModule } from '@greco/ngx-finance-tax';
import { ContactsModule } from '@greco/ngx-identity-contacts';
import { UsersModule } from '@greco/ngx-identity-users';
import { LetModule } from '@greco/ngx-let-util';
import { PerksModule } from '@greco/ngx-sales-perks';
import { PurchasesModule } from '@greco/ngx-sales-purchases';
import { StatsDisplayModule } from '@greco/ngx-sales-stats';
import { BoxModule } from '@greco/ui-box';
import { CollapsibleSectionModule } from '@greco/ui-collapsible-section';
import { EditorModule } from '@greco/ui-editor';
import { FormSaveButtonsModule } from '@greco/ui-form-save-buttons';
import { ImageUploadModule } from '@greco/ui-image-upload';
import { SimpleDialogModule } from '@greco/ui-simple-dialog';
import { TableModule } from '@greco/ui-table';
import { DynamicFormsMaterialUIModule } from '@ng-dynamic-forms/ui-material';
import { NgxCurrencyModule } from 'ngx-currency';
import { NgPipesModule } from 'ngx-pipes';
import { SafePipeModule } from 'safe-pipe';
import {
  CollectionsInputComponent,
  DynamicFormControlCategoryPickerComponent,
  DynamicFormControlProductVariantPickerComponent,
  LazyFormControlComponent,
  ProductAddonsComponent,
  ProductSubscriptionHandlerFormComponent,
  ProductVariantPickerComponent,
  VariantImagePickerComponent,
  VariantPickerComponent,
  VariantPriceComponent,
  VariantTransfersTableComponent,
} from './components';
import {
  ProductCouponAssignmentFormComponent,
  SaleCategoryCouponAssignmentFormComponent,
} from './components/coupon-assignments';
import {
  AddInventoryDialog,
  AddVariantPerkDialog,
  AgreementFormComponent,
  ConfigureAddonDialog,
  CreateProductDialog,
  CreateVariantDialog,
  CreateVariantTransferDialog,
  InventoryFormComponent,
  PRODUCT_CONDITION_FORMS,
  ProductConditionForm,
  ReturnInventoryDialog,
  ShopFormComponent,
  UpdateVariantPerkDialog,
  UpgradeConfigurationFormComponent,
  UserAvailabilityFormComponent,
  VariantLinkDialog,
} from './dialogs';
import { ConfigureConditionDialog } from './dialogs/configure-condition/configure-condition.dialog';
import { InventoriesPage, InventoryAddonPage, ProductPage, ProductsPage, VariantPage } from './pages';
import {
  GetVariantPipe,
  InventoryPipe,
  ProductConditionDetails,
  RestockPipe,
  StriphtmlPipe,
  VariantInventoryPipe,
  VariantOptionPipe,
  VariantPerkPipe,
  VariantPriceBillingPipe,
  VariantPriceCostPipe,
  VariantPriceLabel,
  VariantPricePeriod,
  VariantTitlePipe,
  VariantTransferTriggerPipe,
  VariantTransfersPipe,
  VariantsPipe,
} from './pipes';
import {
  AddonsService,
  CheckoutService,
  CollectionService,
  InventoryService,
  ProductAgreementAddonsService,
  ProductConditionService,
  ProductsService,
  VariantTransferService,
  VariantsService,
} from './services';

@NgModule({
  imports: [
    // Angular
    CommonModule,
    ReactiveFormsModule,
    DynamicFormsMaterialUIModule,
    RouterModule,

    // Material
    MatIconModule,
    MatMenuModule,
    MatInputModule,
    MatChipsModule,
    MatSelectModule,
    MatButtonModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    CdkClipboardModule,

    // Greco
    TimezoneModule,
    LetModule,
    TableModule,
    UsersModule,
    PerksModule,
    EditorModule,
    FiltersModule,
    ContactsModule,
    PaymentsModule,
    JoinPipeModule,
    ClipboardModule,
    PurchasesModule,
    MatStepperModule,
    SimpleDialogModule,
    FormSaveButtonsModule,
    CollapsibleSectionModule,
    AccountSearchComponentModule,
    MatDatepickerModule,
    BoxModule,
    MatRadioModule,
    MatDividerModule,
    MatProgressBarModule,

    EventsModule,
    ImageUploadModule,
    StatsDisplayModule,
    GrecoFinanceFeatureTaxModule,
    AlertsModule,

    // Other
    NgPipesModule,
    SafePipeModule,
    NgxCurrencyModule,
  ],
  declarations: [
    // Components
    ShopFormComponent,
    AgreementFormComponent,
    UserAvailabilityFormComponent,
    VariantPriceComponent,
    VariantPickerComponent,
    ProductVariantPickerComponent,
    ProductAddonsComponent,
    CollectionsInputComponent,
    VariantTransfersTableComponent,
    VariantImagePickerComponent,
    ProductSubscriptionHandlerFormComponent,
    UpgradeConfigurationFormComponent,
    LazyFormControlComponent,
    InventoryFormComponent,

    // Dialogs
    CreateProductDialog,
    CreateVariantDialog,
    AddVariantPerkDialog,
    ConfigureAddonDialog,
    UpdateVariantPerkDialog,
    CreateVariantTransferDialog,
    ReturnInventoryDialog,
    AddInventoryDialog,
    VariantLinkDialog,

    // Pages
    VariantPage,
    ProductPage,
    ProductsPage,
    InventoriesPage,
    InventoryAddonPage,

    // Pipes
    VariantsPipe,
    VariantPerkPipe,
    VariantOptionPipe,
    VariantPriceLabel,
    VariantTransfersPipe,
    VariantTransferTriggerPipe,
    VariantPricePeriod,
    VariantPriceLabel,
    StriphtmlPipe,
    ConfigureConditionDialog,
    DynamicFormControlCategoryPickerComponent,
    DynamicFormControlProductVariantPickerComponent,
    ProductConditionDetails,
    InventoryPipe,
    VariantTitlePipe,
    RestockPipe,
    VariantInventoryPipe,
    GetVariantPipe,

    //Coupons
    ProductCouponAssignmentFormComponent,
    SaleCategoryCouponAssignmentFormComponent,
    VariantPriceBillingPipe,
    VariantPriceCostPipe,
  ],
  providers: [
    AddonsService,
    ProductsService,
    VariantsService,
    CheckoutService,
    CollectionService,
    VariantTransferService,
    ProductConditionService,
    ProductAgreementAddonsService,
    InventoryService,
  ],
  exports: [
    ProductsPage,
    ProductPage,
    VariantPage,
    VariantPickerComponent,
    ProductVariantPickerComponent,
    DynamicFormControlCategoryPickerComponent,
    DynamicFormControlProductVariantPickerComponent,
    // Pipes
    VariantPriceLabel,
    VariantPricePeriod,
    //Coupons
    ProductCouponAssignmentFormComponent,
    SaleCategoryCouponAssignmentFormComponent,
    VariantPriceBillingPipe,
    VariantPriceCostPipe,

    ReturnInventoryDialog,
    InventoriesPage,
    InventoryAddonPage,
  ],
})
export class ProductsModule {
  static forRoot(productConditionForms: ProductConditionForm[]) {
    return {
      ngModule: ProductsModule,
      providers: [{ provide: PRODUCT_CONDITION_FORMS, useValue: productConditionForms }],
    };
  }
}
