<greco-simple-dialog
  *ngIf="communityId"
  [data]="{
    title: 'Create New User Agreement',
    subtitle: 'Provide the details for the new user agreement',
    showCloseButton: false,
    buttons: [
      { label: 'Cancel', role: 'cancel' },
      { label: 'Create', role: 'create', resultFn: getResult }
    ]
  }"
>
  <form [formGroup]="formGroup" style="display: flex; flex-direction: column; gap: 8px; padding: 8px">
    <mat-form-field>
      <mat-label>User</mat-label>
      <greco-contact-picker
        [withUser]="true"
        formControlName="contact"
        [communityId]="communityId"
        [disabled]="disableContact"
        (contact)="setUser($event)"
      >
      </greco-contact-picker>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Agreement</mat-label>
      <greco-community-agreement-picker
        formControlName="agreement"
        [communityId]="communityId"
        (agreement)="fillText($event)"
      ></greco-community-agreement-picker>
    </mat-form-field>

    <ng-container *ngIf="selectedAgreement">
      <div class="agreement-preview" style="padding: 0 8px">
        <span [innerHtml]="selectedAgreement.text | safe:'html'"></span>
      </div>
      <div style="display: flex; justify-content: center">
        <div style="width: 98%; border-bottom: 1px solid darkgray"></div>
      </div>

      <mat-checkbox
        *ngIf="selectedAgreement.agreementType !== 'DIGITAL_SIGNATURE'"
        [checked]="checked"
        [required]="true"
        style="padding: 8px"
        color="primary"
        formControlName="checkbox"
      >
        I have read and agree to the terms of the agreement
      </mat-checkbox>

      <greco-signature
        *ngIf="user && selectedAgreement.agreementType === 'DIGITAL_SIGNATURE'"
        [user]="user"
        [forPurchase]="true"
        (signatureChange)="setSignature($event)"
      >
      </greco-signature>

      <greco-alert
        *ngIf="(canLeaveUnsigned$ | async) && (!formGroup.value.checkbox && !signature)"
        [config]="{
          title: 'Signature Required',
          type: $any('WARN'),
          description:
            (user?.displayName || 'This member') + ' will have to sign this agreement before checking into the club, using any services, or purchasing other items.'
        }"
      ></greco-alert>
    </ng-container>
  </form>
</greco-simple-dialog>
