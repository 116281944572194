<ng-container *grecoLet="pageData$ | async">
  <ng-container *grecoLet="bookingErrors$ | async as bookingErrors">
    <div
      style="width: 100%; background-color: #f5f5f5; min-height: 96px; border-radius: 12px"
      *grecoLet="isMobile$ | async as isMobile"
    >
      <div style="padding: 12px 12px 10px 12px; display: flex; gap: 12px">
        <greco-user-avatar
          *ngIf="!isMobile"
          [user]="user"
          [size]="'large'"
          [showOnlineStatus]="false"
        ></greco-user-avatar>

        <div style="display: flex; flex-grow: 1; overflow: visible">
          <div
            style="
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              flex-grow: 1;
              overflow: visible;
            "
          >
            <div
              style="
                display: flex;
                flex-direction: row-reverse;
                width: 100%;
                justify-content: space-between;
                overflow: visible;
              "
            >
              <mat-icon *ngIf="!isMobile" class="status-icon">edit_calendar</mat-icon>
              <h3
                style="margin: 0; flex-grow: 1; z-index: 1; display: flex; align-items: center; gap: 8px"
                [style.margin-bottom]="isMobile ? '4px' : '0'"
              >
                <greco-user-avatar
                  class="avatar-mobile"
                  *ngIf="isMobile"
                  [user]="user"
                  [size]="'small'"
                  [showOnlineStatus]="false"
                ></greco-user-avatar>
                <span>
                  Booking for <strong>{{ user.friendlyName || user.displayName }}</strong>
                </span>
              </h3>
            </div>

            <!-- Info Buttons -->
            <div
              style="
                display: flex;
                gap: 8px;
                align-items: center;
                flex-direction: row;
                margin-top: 4px;
                flex-grow: 1;
                flex-wrap: wrap;
                overflow: hidden;
              "
            >
              <!-- <button
              *ngIf="isWaitlisted$ | async"
              mat-flat-button
              class="info-button"
              [disabled]="!selectedBookingOption"
              (click)="leaveWaitlist()"
            >
              <span>Leave Waitlist</span>

              <mat-icon style="color: var(--primary-color)">logout</mat-icon>
            </button> -->

              <button
                [disabled]="
                  confirming ||
                  (!selectedBookingOption &&
                    !isStaffView &&
                    (event.security.canBookPending || event.security.canBookComplimentary))
                "
                mat-flat-button
                class="info-button"
                (click)="optionPicker.open()"
              >
                <ng-container *ngIf="selectedBookingOption; else noOptionSelected">
                  <mat-icon [style.color]="selectedBookingOption.badge.color">local_activity</mat-icon>

                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: start;
                      gap: 2px;
                      overflow: visible;
                      padding: 2px 0;
                    "
                  >
                    <p style="margin-bottom: 0; line-height: 16px; text-wrap: wrap">
                      {{ selectedBookingOption.title }}
                    </p>
                    <p
                      *ngIf="selectedBookingOption.userId && user.id !== eventAccounts[0].user.id"
                      style="margin-bottom: 0; line-height: 16px; width: 100%"
                    >
                      <small
                        *grecoLet="(selectedBookingOption.userId | getEventAccount : eventAccounts)?.user as optionUser"
                        style="display: flex; align-items: center; gap: 4px; margin-top: -3px"
                      >
                        {{
                          isStaffView || selectedBookingOption.userId !== eventAccounts![0].user.id
                            ? (optionUser?.friendlyName || optionUser?.displayName) + "'s"
                            : 'Your'
                        }}
                        Perk
                        <mat-icon
                          *ngIf="optionUser?.id === eventAccounts[0].user.id"
                          style="width: 16px; height: 16px; font-size: 16px; line-height: 16px"
                        >
                          {{
                            selectedBookingOption.userId !== eventAccounts![0].user.id
                              ? 'supervisor_account'
                              : 'swap_horizontal'
                          }}
                        </mat-icon>
                      </small>
                    </p>
                  </div>
                </ng-container>

                <ng-template #noOptionSelected>
                  <mat-icon [style.color]="'var(--warn-color)'">error</mat-icon>
                  <p style="margin-bottom: 0; font-size: 12px; line-height: 12px">Select a Perk</p>
                </ng-template>

                <mat-icon>edit</mat-icon>
              </button>

              <div
                [matTooltip]="
                  !event.requirements.spotBooking?.spotBookingEnabled
                    ? 'Spot booking is disabled for this event. Staff will assign spots for you.'
                    : ''
                "
                [style.flex-grow]="isMobile ? '1' : '0'"
              >
                <button
                  *ngIf="
                    event.requirements.spotBooking?.room &&
                    ((spot$ | async)?.name || event.requirements.spotBooking?.spotBookingEnabled)
                  "
                  mat-flat-button
                  class="info-button"
                  [style.width]="isMobile ? '100%' : 'auto'"
                  [disabled]="confirming || !event.requirements.spotBooking?.spotBookingEnabled"
                  (click)="bottomSheet.open(spotSelectionSheet, { panelClass: 'bottom-sheet' })"
                >
                  <mat-icon>person_pin</mat-icon>

                  <span> {{ (spot$ | async)?.name || 'Unassigned Spot' }} </span>

                  <mat-icon *ngIf="event.requirements.spotBooking?.spotBookingEnabled">edit</mat-icon>
                  <mat-icon *ngIf="!event.requirements.spotBooking?.spotBookingEnabled">lock</mat-icon>
                </button>
              </div>
              <div
                *ngIf="event?.equipmentTitle && event?.equipmentOptions?.length"
                [matTooltip]="'Select the equipment you would like to use for this event.'"
                [style.flex-grow]="isMobile ? '1' : '0'"
              >
                <button
                  *grecoLet="selectedEquipment$ | async as selectedEquipment"
                  mat-flat-button
                  class="info-button"
                  [disabled]="confirming"
                  [matMenuTriggerFor]="equipmentMenu"
                  [style.width]="isMobile ? '100%' : 'auto'"
                >
                  {{ selectedEquipment || event.equipmentTitle }}
                  <mat-icon>fitness_center</mat-icon>
                </button>

                <mat-menu #equipmentMenu="matMenu" xPosition="before">
                  <div *ngFor="let option of event.equipmentOptions">
                    <button mat-menu-item (click)="selectEquipment(option)">
                      {{ option }}
                    </button>
                  </div>
                </mat-menu>
              </div>

              <ng-template #spotSelectionSheet>
                <div class="header">
                  <h2 #header style="margin-bottom: 0">Select a Spot</h2>

                  <button mat-icon-button (click)="bottomSheet.dismiss()">
                    <mat-icon>close</mat-icon>
                  </button>
                </div>

                <alt-room-spot-picker
                  [event]="event"
                  [selectedSpotId]="spotId"
                  (spotSelected)="updateSelectedSpot($event)"
                ></alt-room-spot-picker>

                <button
                  *ngIf="selectedSpot?.spotId !== spotId"
                  mat-flat-button
                  class="confirm"
                  color="primary"
                  (click)="updateSpot(); bottomSheet.dismiss()"
                >
                  Confirm Selection
                </button>
              </ng-template>
            </div>
          </div>
        </div>
      </div>

      <!-- Card Body -->
      <div class="alert-container">
        <ng-container *grecoLet="eventSvc.total$ | async as total">
          <greco-alert
            *ngIf="total && total > 0"
            style="margin-bottom: 8px"
            [config]="{
              title: 'Purchase Required',
              type: $any('INFO'),
              description: everyoneText
            }"
          ></greco-alert>
        </ng-container>

        <ng-container *ngFor="let unsignedAgreement of eventSvc.unsignedAgreements$ | async">
          <greco-alert
            style="margin-bottom: 8px"
            [config]="{
              title: 'Your ' + unsignedAgreement?.signedAgreementTitle + ' needs to be signed',
              type: $any('DANGER'),
              description: 'You will need to sign the agreement before completing your booking'
            }"
          >
            <button
              mat-stroked-button
              slot="buttons"
              color="error"
              style="border-color: rgba(var(--warn-color-rgb), 0.3)"
              (click)="signAgreement(unsignedAgreement)"
            >
              Sign
            </button>
          </greco-alert>
        </ng-container>

        <ng-container *ngIf="bookingErrors && selectedBookingOption">
          <greco-alert
            *ngIf="
              !bookingErrors.error &&
                !bookingErrors.warning &&
                (!user.address?.line1 ||
                  event.requirements.forms?.length ||
                  event.requirements.agreements?.length ||
                  hasBookingAgreements);
              else errorAlert
            "
            style="margin-bottom: 8px"
            [config]="{
              type: $any('SUCCESS'),
              icon: 'check_circle',
              title: 'All Information Completed'
            }"
          >
            <button
              slot="buttons"
              mat-stroked-button
              (click)="bottomSheet.open(bottomSheetContent, { panelClass: 'bottom-sheet' })"
              style="color: var(--success-color); border-color: rgba(var(--success-color-rgb), 0.3)"
            >
              Review
            </button>
          </greco-alert>

          <ng-template #errorAlert>
            <greco-alert
              *ngIf="bookingErrors.error; else warningAlert"
              style="margin-bottom: 8px"
              [config]="{
                type: $any('DANGER'),
                icon: 'person_alert',
                title: 'Missing Information'
              }"
            >
              <button
                slot="buttons"
                mat-stroked-button
                (click)="bottomSheet.open(bottomSheetContent, { panelClass: 'bottom-sheet' })"
                style="color: var(--warn-color); border-color: rgba(var(--warn-color-rgb), 0.3)"
              >
                Review
              </button>
            </greco-alert>
          </ng-template>

          <ng-template #warningAlert>
            <greco-alert
              *ngIf="
                !user.address?.line1 ||
                event.requirements.forms?.length ||
                event.requirements.agreements?.length ||
                (hasBookingAgreements && !allBookingAgreementsSigned)
              "
              style="margin-bottom: 8px"
              [config]="{
                type: $any('WARN'),
                icon: 'warning',
                title: 'Review Information'
              }"
            >
              <button
                slot="buttons"
                mat-stroked-button
                (click)="bottomSheet.open(bottomSheetContent, { panelClass: 'bottom-sheet' })"
                style="color: rgb(255, 165, 0); border-color: rgba(255, 165, 0, 0.3)"
              >
                Review
              </button>
            </greco-alert>
          </ng-template>
        </ng-container>

        <ng-container *ngFor="let alert of unsignedAgreementAlerts$ | async">
          <greco-alert
            style="margin-bottom: 8px"
            [config]="{
              type: alert.type,
              title: alert.title,
              description: alert.description
            }"
          >
          </greco-alert>
        </ng-container>

        <alt-booking-option-picker2
          #optionPicker
          [user]="user"
          [event]="event"
          [isStaffView]="isStaffView"
          [eventAccounts]="eventAccounts"
          [bookingOption]="selectedBookingOption"
          [boostersActivated]="boostersActivated"
          style="flex-grow: 1"
        ></alt-booking-option-picker2>

        <alt-event-schedule-conflict-alert
          [user]="user"
          [event]="event"
          [eventAccounts]="eventAccounts"
        ></alt-event-schedule-conflict-alert>

        <ng-container *grecoLet="eventSvc.usersWithoutEquipment$ | async as usersWithoutEquipment">
          <greco-alert
            *ngIf="event?.equipmentOptions?.length && usersWithoutEquipment?.includes(user.id)"
            style="margin-bottom: 8px"
            [config]="{
              type: $any('INFO'),
              title: event.equipmentTitle || 'Equipment Response Required',
              description: 'This event requires equipment. Please select an equipment option above before booking.'
            }"
          >
          </greco-alert>
        </ng-container>

        <!-- <greco-alert
        *ngIf="!bookingErrors?.error && user.id !== eventAccounts[0].user.id && !user.address?.line1"
        style="margin-bottom: 8px"
        [config]="{
          type: $any('WARN'),
          title: 'Guest Profile Incomplete',
          description:
            'We are missing required information about your guest. They may be required by staff to fill it out prior to admittance into the event'
        }"
      ></greco-alert> -->
      </div>

      <!-- Card Footer -->
      <div class="card-footer">
        <alt-cancellation-policy
          *ngIf="selectedBookingOption"
          [event]="event"
          [interactive]="true"
          [bookingOption]="selectedBookingOption"
        ></alt-cancellation-policy>

        <div *ngIf="!readonly" class="card-icon" style="flex-grow: 1">
          <div
            *ngIf="selectedBookingOption?.additionalSpots"
            style="display: flex"
            [matTooltip]="
              'You may book up to ' +
              selectedBookingOption?.additionalSpots +
              ' additional ' +
              ((selectedBookingOption?.additionalSpots || 0) > 1 ? ' spots' : ' spot') +
              ' for this event at a cost of $' +
              ((selectedBookingOption?.additionalSpotCost || 0) / 100).toFixed(2) +
              ' per spot'
            "
            [style.flex-grow]="isMobile ? '1' : '0'"
          >
            <ng-container *ngIf="eventSvc.additionalSpots$ | async as additionalSpots">
              <button
                mat-flat-button
                class="info-button"
                style="border-bottom-right-radius: 0; border-top-right-radius: 0; padding: 0; margin-right: -16px"
                [disabled]="confirming || (additionalSpots?.length || 0) === 0"
                (click)="removeAdditionalSpot()"
              >
                <mat-icon style="margin-left: -2px; border: 0; padding: 4px 12px"> person_remove </mat-icon>
              </button>

              <button
                *ngIf="event.capacityRemaining > 1"
                mat-flat-button
                class="info-button"
                style="
                  border-radius: 0px;
                  border-right: 2px solid lightgray;
                  border-left: 2px solid lightgray;
                  z-index: 10;
                "
                [disabled]="confirming"
                [style.width]="isMobile ? '100%' : 'auto'"
                (click)="addAdditionalSpot()"
              >
                <span>
                  {{ additionalSpots?.length === 0 ? 'No' : additionalSpots?.length }} Additional Spot{{
                    additionalSpots?.length === 1 ? '' : 's'
                  }}
                </span>
              </button>

              <button
                *grecoLet="
                  !(
                    (additionalSpots?.length || 0) <
                    (canOverrideAdditionalSpots
                      ? event.capacityRemaining - 1
                      : selectedBookingOption?.additionalSpots || 0)
                  ) as disabled
                "
                mat-flat-button
                class="info-button"
                style="border-bottom-left-radius: 0; border-top-left-radius: 0; padding: 0; margin-left: -14px"
                [disabled]="confirming || disabled"
                (click)="addAdditionalSpot()"
              >
                <mat-icon style="padding: 4px 12px; margin-left: 12px; border: 0"> person_add </mat-icon>
              </button>
            </ng-container>
          </div>

          <ng-container
            *ngIf="(event.security.canAccessLinkedAccounts || event.security.canBookGuest) && eventAccounts.length > 1"
          >
            <!-- Remove -->
            <div
              *ngIf="bookingCount > 1"
              [matTooltip]="
                !allowRemoval && bookingCount <= 1
                  ? 'This booking cannot be removed while it is the only booking.'
                  : 'Remove this booking.'
              "
            >
              <button
                mat-icon-button
                [disabled]="confirming || (!allowRemoval && bookingCount <= 1)"
                [style.box-shadow]="
                  confirming || (!allowRemoval && bookingCount <= 1) ? 'none' : '0 1px 2px rgba(0, 0, 0, 0.05)'
                "
                (click)="removeBooking()"
              >
                <mat-icon>person_remove</mat-icon>
              </button>
            </div>

            <!-- Swap -->
            <div
              *ngIf="altAccounts.length"
              [matTooltip]="
                !altAccounts.length
                  ? 'No other alternate accounts to swap with.'
                  : 'Swap this booking with an alternate account.'
              "
            >
              <button
                #menuTrigger="matMenuTrigger"
                mat-icon-button
                [disabled]="confirming || !altAccounts.length"
                [matMenuTriggerFor]="linkedAccountsMenu"
                [style.box-shadow]="confirming || !altAccounts.length ? 'none' : '0 1px 2px rgba(0, 0, 0, 0.05)'"
              >
                <mat-icon>social_distance</mat-icon>
              </button>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<mat-menu class="linked-accounts-menu" #linkedAccountsMenu="matMenu" xPosition="before">
  <!-- Children -->
  <div
    *ngFor="let account of altAccounts"
    class="account-item"
    (click)="account.userStatusInfo === 'booked' ? $event.stopImmediatePropagation() : swapBooking(account)"
  >
    <h3>{{ account.user.friendlyName || account.user.displayName }}</h3>

    <div *ngIf="account.userStatusInfo === 'booked'">
      <mat-icon style="color: var(--success-color)" matTooltip="Already booked for this event!">
        event_available
      </mat-icon>
    </div>
  </div>
</mat-menu>

<ng-template #bottomSheetContent>
  <h2>Information Required for {{ user.friendlyName || user.displayName }}</h2>

  <div class="card-content">
    <!-- Cancellation Policy -->
    <alt-cancellation-policy
      class="requirement-item"
      *ngIf="selectedBookingOption"
      [event]="event"
      [bookingOption]="selectedBookingOption"
      (bottomSheetOpened)="openRequirementRef($event)"
    ></alt-cancellation-policy>

    <!-- Profile completion -->
    <alt-profile-completion-requirement
      *ngIf="!user.address?.line1"
      class="requirement-item"
      [user]="user"
      [required]="user.id === eventAccounts[0].user.id"
      (bottomSheetOpened)="openRequirementRef($event)"
    ></alt-profile-completion-requirement>

    <!-- Spot Booking -->
    <alt-spot-selection-requirement
      class="requirement-item"
      [user]="user"
      [event]="event"
      (bottomSheetOpened)="openRequirementRef($event)"
    >
    </alt-spot-selection-requirement>

    <!-- Typeform requirements -->
    <alt-typeform-requirement
      class="requirement-item"
      *ngFor="let form of event.requirements.forms"
      [user]="user"
      [requirement]="form"
    ></alt-typeform-requirement>

    <!-- Agreements and signatures -->
    <alt-agreements-requirement
      *grecoLet="eventSvc.bookingOptionAgreements$ | async as bookingOptionAgreements"
      class="requirement-item"
      [user]="user"
      [readonly]="readonly"
      [agreements]="event.requirements.agreements || []"
      [bookingOptionAgreements]="bookingOptionAgreements || []"
      [canLeaveUnsigned]="(canLeaveUnsigned$ | async) === true"
      (bottomSheetOpened)="openRequirementRef($event)"
    ></alt-agreements-requirement>
  </div>
</ng-template>

<!-- loads the requirements so the error check is correct since while in the bottom sheet they do not load until it is opened -->
<div style="visibility: hidden; max-height: 0">
  <alt-agreements-requirement
    [user]="user"
    [agreements]="event.requirements.agreements || []"
    [canLeaveUnsigned]="(canLeaveUnsigned$ | async) === true"
  ></alt-agreements-requirement>

  <alt-typeform-requirement
    *ngFor="let form of event.requirements.forms"
    [user]="user"
    [requirement]="form"
  ></alt-typeform-requirement>
</div>
