<ng-container *ngIf="contact">
  <ng-container *ngIf="emailBlacklisted$ | async">
    <greco-alert
      [config]="{
        title: 'Email Blacklisted',
        type: $any('WARN'),
        description: 'Please update the email of the member or remove the email from the blacklist'
      }"
    >
      <button
        *ngIf="canManageContact$ | async"
        mat-flat-button
        slot="buttons"
        color="primary"
        (click)="removeBlacklist(contact.email)"
      >
        <span>Remove Blacklist</span>
      </button>
    </greco-alert>
  </ng-container>
  <!-- Contact Details -->
  <ng-container *ngIf="!contact.user">
    <greco-collapsible-section
      [expanded]="true"
      [disabled]="true"
      [header]="{ title: contact.displayName, icon: 'group' }"
    >
      <greco-form-save-buttons
        header
        [form]="formGroup"
        [resetValue]="resetValue"
        [saveAction]="save"
      ></greco-form-save-buttons>

      <form [formGroup]="formGroup">
        <mat-form-field appearance="standard">
          <mat-label>Display Name</mat-label>
          <input matInput required type="text" formControlName="displayName" />
        </mat-form-field>

        <mat-form-field appearance="standard">
          <mat-label>Email Address</mat-label>
          <input matInput required type="email" formControlName="email" />
        </mat-form-field>

        <mat-form-field appearance="standard">
          <mat-label>Phone Number</mat-label>
          <input matInput required formControlName="phoneNumber" type="tel" mask="(000) 000-0000" />
        </mat-form-field>
      </form>
    </greco-collapsible-section>
  </ng-container>

  <ng-container *ngIf="contact.user">
    <ng-container *grecoLet="isMobile$ | async as isMobile">
      <!-- Member number -->
      <button
        *ngIf="isMobile &&(!contact.memberNumber || !(contact.memberNumber.length))"
        mat-flat-button
        color="primary"
        header
        (click)="$event.stopImmediatePropagation(); openDialog()"
      >
        <mat-icon>create</mat-icon>
        <span>Create Member Number</span>
      </button>

      <!-- Profile -->
      <greco-user-profile-details
        [readonly]=" ((isSuper$ | async) === false) && (!contact.subscribedDate || ((canManageContact$ | async) === false))"
        [user]="contact.user"
        (saved)="refresh()"
      >
        <ng-container header>
          <ng-container [ngTemplateOutlet]="profileButtons"></ng-container>
        </ng-container>

        <ng-container staffHeader>
          <ng-container [ngTemplateOutlet]="profileButtons"></ng-container>
        </ng-container>
      </greco-user-profile-details>

      <!-- Contact Details -->
      <greco-membership-number-input
        *ngIf="contact.memberNumber"
        [readonly]=" ((isSuper$ | async) === false) && (!contact.subscribedDate || ((canManageContact$ | async) === false))"
        [contact]="contact"
        (saved)="refresh()"
      ></greco-membership-number-input>

      <ng-template #profileButtons>
        <!-- Child chip -->
        <div
          header
          style="display: flex; flex-direction: row; gap: 0.7rem; align-items: center"
          *ngIf="!contact.user?.email"
        >
          <small>{{ contact.user.contactEmail }}</small>
          <mat-chip style="background-color: var(--accent2-color)">Child</mat-chip>
        </div>

        <!-- Member number -->
        <button
          *ngIf="!isMobile && (!contact.memberNumber || !(contact.memberNumber.length))"
          mat-flat-button
          color="primary"
          header
          (click)="$event.stopImmediatePropagation(); openDialog()"
        >
          <mat-icon>create</mat-icon>
          <span>Create Member Number</span>
        </button>

        <!-- More Options -->
        <ng-container *ngIf="contact.user?.email && (canManageAuth$ | async)">
          <button
            mat-icon-button
            #menuTrigger="matMenuTrigger"
            [matMenuTriggerFor]="moreProfileOptions"
            (click)="$event.stopImmediatePropagation();"
          >
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #moreProfileOptions="matMenu">
            <button
              color="primary"
              style="flex: 1"
              mat-menu-item
              (click)="$event.stopImmediatePropagation(); verifyEmail(); menuTrigger.closeMenu()"
              *ngIf="!contact.user?.emailVerified"
            >
              <mat-icon>check_circle</mat-icon>
              <span>Verify Email</span>
            </button>

            <button
              mat-menu-item
              color="primary"
              style="flex: 1"
              (click)="$event.stopImmediatePropagation(); sendResetEmail(); menuTrigger.closeMenu()"
            >
              <mat-icon>send</mat-icon>
              <span>Send Password Reset Email</span>
            </button>

            <button
              style="flex: 1"
              color="primary"
              mat-menu-item
              [disabled]="loadingResetLink"
              (click)="$event.stopImmediatePropagation(); generateResetLink(); menuTrigger.closeMenu()"
            >
              <mat-icon>link</mat-icon>
              <span>Generate Password Reset Link</span>
            </button>
          </mat-menu>
        </ng-container>
      </ng-template>
    </ng-container>
  </ng-container>
</ng-container>
