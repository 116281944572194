import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { toPromise } from '@greco-fit/util';
import { Payment, PaymentMethod } from '@greco/finance-payments';
import { PaymentMethodValidator } from '@greco/ngx-finance-payments';
import { PaymentHandler } from '@greco/ngx-sales-purchases';
import { Purchase } from '@greco/sales-purchases';
import {
  ICollectRefundPaymentMethodResponse,
  ISdkManagedPaymentIntent,
  loadStripeTerminal,
  Reader,
  StripeTerminal,
  Terminal,
} from '@stripe/terminal-js';
import { Observable, ReplaySubject } from 'rxjs';
import type { Stripe } from 'stripe';
import { TerminalErrorDialog } from '../dialogs/terminal-error/terminal-error.dialog';

export interface TerminalServiceConfig {
  useSimulator?: boolean;
}

export const TERMINAL_SERVICE_CONFIG = new InjectionToken<TerminalServiceConfig>('TERMINAL_SERVICE_CONFIG');

@Injectable()
export class TerminalService implements PaymentHandler, PaymentMethodValidator {
  private _loaded = new ReplaySubject<void>(1);

  private _instance: StripeTerminal | null = null;
  private get instance() {
    return toPromise(this._loaded).then(() => {
      if (!this._instance) throw new Error('Unable to load @stripe/terminal-js');
      return this._instance;
    });
  }

  constructor(
    @Optional() @Inject(TERMINAL_SERVICE_CONFIG) private config: TerminalServiceConfig | null,
    private dialogs: MatDialog,
    private http: HttpClient
  ) {
    loadStripeTerminal().then(instance => {
      if (instance) {
        this._instance = instance;
        this._loaded.next();
      } else {
        throw new Error('Unable to load @stripe/terminal-js');
      }
    });
  }

  private async terminal() {
    const instance = await this.instance;
    const terminal = instance.create({
      onFetchConnectionToken: async () => {
        const token = await toPromise(
          this.http.get<Stripe.Terminal.ConnectionToken>('/api/stripe/terminals/connection_token')
        );
        return token.secret;
      },
      onUnexpectedReaderDisconnect: event => {
        console.error('TerminalService.onUnexpectedReaderDisconnect', event);
      },
    });

    terminal.setSimulatorConfiguration({ testCardNumber: '4242424242424242' });
    return terminal;
  }

  async getTerminalPaymentMethods(): Promise<PaymentMethod[]> {
    return await toPromise(this.http.get<PaymentMethod[]>('/api/stripe/terminals/payment_methods'));
  }

  async addReaderAsPaymentMethod(readerId: string) {
    return await toPromise(this.http.post<any>('/api/stripe/terminals/payment_methods', { readerId }));
  }

  async getPaymentIntent(id: string) {
    return await toPromise(this.http.get<Stripe.PaymentIntent>(`/api/stripe/terminals/payment_intent/${id}`));
  }

  async discoverReaders(_terminal?: Terminal) {
    const terminal = _terminal ?? (await this.terminal());
    if (!terminal) throw new Error();

    const result = await terminal.discoverReaders({ simulated: this.config?.useSimulator || false });
    if ('error' in result) {
      console.error('TerminalService.discoverReaders', result.error);
      return [];
    }

    return result.discoveredReaders;
  }

  async updateReaderLabel(id: string) {
    const label = prompt('Reader label');
    if (label) await toPromise(this.http.patch(`/api/stripe/terminals/${id}/label`, { label })).then(console.log);
  }

  async validatePaymentMethod(paymentMethod: PaymentMethod): Promise<boolean> {
    if (paymentMethod.model !== 'terminal') return true;

    const readers = await this.discoverReaders().catch(() => []);
    const reader = readers.find(reader => reader.id === paymentMethod.externalId);

    return reader?.status === 'online';
  }

  async handlePendingPayment(purchase: Purchase & { payment: Payment }, cancel$?: Observable<void>): Promise<void> {
    if (purchase.payment.externalId && purchase.payment.paymentMethod?.model === 'terminal') {
      const { client_secret } = await this.getPaymentIntent(purchase.payment.externalId);
      if (!client_secret) throw new Error();

      const terminal = await this.terminal();
      if (!terminal) throw new Error();

      let canceled = false;

      const connectReader = async (readerId: string) => {
        const readers = await this.discoverReaders(terminal);
        const reader = readers.find(r => r.id === readerId);
        if (!reader || reader.status !== 'online') throw new Error();

        const response = await terminal.connectReader(reader, { fail_if_in_use: true });
        if ('error' in response) {
          if (canceled) return null;

          const data = { terminal, reader, error: response.error };
          const retry = await toPromise(
            this.dialogs.open(TerminalErrorDialog, { data, disableClose: true }).afterClosed()
          );
          if (!retry) throw new Error();
        }

        return reader;
      };

      const collectPaymentMethod = async (
        reader: Reader,
        clientSecret: string
      ): Promise<ISdkManagedPaymentIntent | null> => {
        const response = await terminal.collectPaymentMethod(clientSecret, { config_override: { skip_tipping: true } });

        if ('error' in response) {
          if (canceled) return null;

          const data = { terminal, reader: terminal.getConnectedReader(), error: response.error };
          const retry = await toPromise(
            this.dialogs.open(TerminalErrorDialog, { data, disableClose: true }).afterClosed()
          );

          if (retry) {
            return await collectPaymentMethod(reader, clientSecret);
          } else {
            throw new Error();
          }
        }

        return response.paymentIntent;
      };

      const processPayment = async (intent: ISdkManagedPaymentIntent) => {
        // In case it was cancelled, but loaded on the terminal after.
        if (canceled) return;
        const response = await terminal.processPayment(intent);
        // TODO: Notify staff and allow retrying same terminal, selecting other terminal, or cancelling (gracefully?)
        if ('error' in response) {
          if (canceled) return;
          console.error(response.error);
          throw new Error();
        }

        return response.paymentIntent;
      };

      const payOnReader = async (readerId: string, clientSecret: string) => {
        const reader = await connectReader(readerId);
        if (!reader) return;

        const intent = await collectPaymentMethod(reader, clientSecret);
        if (!intent) return;

        await processPayment(intent);
      };

      await Promise.race([
        payOnReader(purchase.payment.paymentMethod.externalId, client_secret),
        ...(cancel$
          ? [
              toPromise(cancel$).then(() => {
                canceled = true;
                terminal.clearReaderDisplay();
              }),
            ]
          : []),
      ]);
    }
  }

  async handleInteracRefund(
    purchase: Purchase & { payment: Payment },
    terminalId: string,
    refundAmount: number,
    cancel$?: Observable<void>
  ): Promise<string | null> {
    if (purchase.payment.gatewayMetadata?.payment_method_type !== 'interac_present') return null;

    const terminal = await this.terminal();
    if (!terminal) throw new Error();

    let canceled = false;

    const connectReader = async (readerId: string) => {
      const readers = await this.discoverReaders(terminal);
      const reader = readers.find(r => r.id === readerId);
      if (!reader || reader.status !== 'online') throw new Error();

      const response = await terminal.connectReader(reader, { fail_if_in_use: true });
      if ('error' in response) {
        if (canceled) return null;

        const data = { terminal, reader, error: response.error };
        const retry = await toPromise(
          this.dialogs.open(TerminalErrorDialog, { data, disableClose: true }).afterClosed()
        );
        if (!retry) throw new Error();
      }

      return reader;
    };

    const collectPaymentMethod = async (
      reader: Reader,
      chargeId: string,
      currency: string
    ): Promise<ICollectRefundPaymentMethodResponse | null> => {
      const response = await terminal.collectRefundPaymentMethod(chargeId, refundAmount, currency);

      if ('error' in response) {
        if (canceled) return null;

        const data = { terminal, reader: terminal.getConnectedReader(), error: response.error };
        const retry = await toPromise(
          this.dialogs.open(TerminalErrorDialog, { data, disableClose: true }).afterClosed()
        );

        if (retry) {
          return await collectPaymentMethod(reader, chargeId, currency);
        } else {
          throw new Error();
        }
      }

      return response;
    };

    const processRefund = async () => {
      const response = await terminal.processRefund();

      if ('error' in response) {
        if (canceled) return null;
        console.error(response.error);
        throw new Error();
      }

      return response.refund.id ?? null;
    };

    const refundOnReader = async () => {
      const { chargeId, currency } = purchase.payment.gatewayMetadata ?? {};
      if (!chargeId || !currency) return null;

      // TODO: prompt for terminal
      const reader = await connectReader(terminalId);
      if (!reader) return null;

      const pm = await collectPaymentMethod(reader, chargeId, currency);
      if (!pm) return null;

      return await processRefund();
    };

    return await Promise.race([
      refundOnReader(),
      ...(cancel$
        ? [
            toPromise(cancel$)
              .then(() => (canceled = true))
              .then(() => terminal.clearReaderDisplay())
              .catch()
              .then(() => null),
          ]
        : []),
    ]);
  }
}
