<div [formGroup]="agreementsForm">
  <ng-container *ngFor="let usage of combinedAgreements$ | async; let index = index">
    <greco-item class="agreement-usage" [lines]="false">
      <mat-icon item-start>assignment</mat-icon>

      <div style="width: 100%">
        <p style="margin-bottom: 0; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; font-weight: bold">
          {{ usage.agreement?.title }}
        </p>

        <div
          *ngIf="!readonly; else readonlyAgreements"
          style="
            display: flex;
            flex-direction: column;
            margin-bottom: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: smaller;
          "
        >
          <mat-checkbox
            *ngIf="usage.agreement?.agreementType == 'CHECKBOX'"
            color="primary"
            [formControlName]="usage.id"
            [checked]="agreementsForm.get(usage.id)?.value"
            (click)="setCheck(usage.id, usage.agreementId); $event.stopImmediatePropagation(); $event.preventDefault()"
          >
            I have read and agree to the terms of the agreement
          </mat-checkbox>

          <mat-checkbox
            *ngIf="usage.agreement?.agreementType == 'AUTO_CHECKBOX'"
            color="primary"
            [formControlName]="usage.id"
            [checked]="agreementsForm.get(usage.id)?.value"
            (click)="setCheck(usage.id, usage.agreementId); $event.stopImmediatePropagation(); $event.preventDefault()"
          >
            I have read and agree to the terms of the agreement
          </mat-checkbox>

          <ng-container *ngIf="usage.agreement?.agreementType == 'DIGITAL_SIGNATURE'">
            <span *ngIf="!hideSignLater && !agreementsForm.get(usage.id)?.value" style="color: var(--warn-color)">
              * Signature Required
            </span>
            <span *ngIf="agreementsForm.get(usage.id)?.value">Digitally Signed</span>
          </ng-container>
        </div>

        <ng-template #readonlyAgreements>
          <p
            style="margin-bottom: 0; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; font-size: smaller"
          >
            You have agreed to the terms of this agreement.
          </p>
        </ng-template>
      </div>

      <div
        *grecoLet="agreementsForm.get(usage.id)?.value as signed"
        item-end
        style="display: flex; align-items: center; justify-content: center; gap: 12px; margin-left: 8px"
      >
        <button
          mat-button
          style="height: 32px; margin-left: auto; padding: 0 12px"
          [color]="signed ? 'primary' : 'accent'"
          (click)="emitBottomSheetOpened(agreementContent)"
        >
          <span>
            {{
              usage.agreement?.agreementType == 'DIGITAL_SIGNATURE' && !agreementsForm.get(usage.id)?.value && !readonly
                ? 'Sign'
                : usage.agreement?.agreementType == 'DIGITAL_SIGNATURE' &&
                  agreementsForm.get(usage.id)?.value &&
                  !readonly
                ? 'Sign Again'
                : 'Details'
            }}
          </span>
        </button>

        <mat-icon *ngIf="signed" style="color: var(--success-color)">check_circle</mat-icon>

        <ng-template #agreementContent>
          <div class="header">
            <h2 #header style="margin-bottom: 0">{{ usage.agreement?.title }}</h2>
            <button mat-icon-button (click)="bottomSheet.dismiss()">
              <mat-icon>close</mat-icon>
            </button>
          </div>

          <div style="display: flex; flex-direction: column; gap: 8px; padding: 8px">
            <span [innerHtml]="usage.agreement?.text || '' | safe : 'html'"></span>
          </div>

          <div *ngIf="!readonly" style="display: flex; flex-direction: columns">
            <mat-checkbox
              *ngIf="usage.agreement?.agreementType == 'CHECKBOX'"
              color="primary"
              [required]="true"
              [formControlName]="usage.id"
              [checked]="agreementsForm.get(usage.id)?.value"
              (click)="setCheck(usage.id, usage.agreementId); $event.preventDefault()"
            >
              I have read and agree to the terms of the agreement
            </mat-checkbox>

            <mat-checkbox
              *ngIf="usage.agreement?.agreementType == 'AUTO_CHECKBOX'"
              color="primary"
              [required]="true"
              [formControlName]="usage.id"
              [checked]="agreementsForm.get(usage.id)?.value"
              (click)="setCheck(usage.id, usage.agreementId); $event.preventDefault()"
            >
              I have read and agree to the terms of the agreement
            </mat-checkbox>

            <greco-signature
              *ngIf="usage.agreement?.agreementType == 'DIGITAL_SIGNATURE'"
              ngDefaultControl
              style="flex: 1"
              [user]="user"
              [forPurchase]="true"
              [formControlName]="usage.id"
              (signatureChange)="setSignature($event, usage.id, usage.agreementId)"
            >
            </greco-signature>
          </div>

          <button
            class="back-to-top"
            mat-mini-fab
            matTooltip="Back to top"
            (click)="header.scrollIntoView({ behavior: 'smooth' })"
          >
            <mat-icon>vertical_align_top</mat-icon>
          </button>
        </ng-template>
      </div>
    </greco-item>

    <ng-container *grecoLet="canContinueWithoutSigning$ | async">
      <greco-alert
        *ngIf="hideSignLater"
        [config]="{
          title: 'Signature Required',
          type: $any('WARN'),
          description:
            (user.displayName || 'This member') +
            ' will have to sign this agreement before checking into the club, using any services, or purchasing other items.'
        }"
      ></greco-alert>
    </ng-container>
  </ng-container>
</div>
