import { Component, EventEmitter, Inject, Injectable, Input, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { PaginatedDto } from '@greco-fit/nest-utils';
import { DialogData } from '@greco-fit/scaffolding';
import { toPromise } from '@greco-fit/util';
import { CommunityAgreement, CommunityAgreementUsage } from '@greco/community-agreements';
import { Community } from '@greco/identity-communities';
import { CommunityAgreementsService } from '@greco/ngx-community-agreements';
import { BuildSearchFilter } from '@greco/ngx-filters';
import { PropertyListener } from '@greco/property-listener-util';
import { SimpleDialog } from '@greco/ui-simple-dialog';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { IPaginationMeta, IPaginationOptions } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { ViewCommunityAgreementDialog } from '../../dialogs';
import { EventCommunityAgreementsUsageService } from '../../services/event-community-agreements-usage.service';

@Injectable({ providedIn: 'any' })
export class TitleSearchFilter extends BuildSearchFilter('TitleSearchFilter', {
  properties: ['title'],
  propertyLabels: ['Title'],
}) {}

@Component({
  selector: 'greco-select-community-agreement',
  templateUrl: './select-community-agreement.component.html',
  styleUrls: ['./select-community-agreement.component.scss'],
})
export class SelectCommunityAgreementComponent {
  constructor(
    private eventAgrUsageSvc: EventCommunityAgreementsUsageService,
    private commAgreementSvc: CommunityAgreementsService,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.data = { ...data };
  }

  @PropertyListener('community') private _community$ = new BehaviorSubject<Community | null>(null);
  @Input() community!: Community;

  @PropertyListener('linkedIds') private _linkedIds$ = new BehaviorSubject<string[]>(['']);
  @Input() linkedIds: string[] = [''];

  @PropertyListener('usages') private _usages$ = new BehaviorSubject<CommunityAgreementUsage[]>([]);
  @Input() usages!: CommunityAgreementUsage[];

  @Input() eventId?: string;

  @Output() confirmed = new EventEmitter<CommunityAgreement[]>();

  refresh$ = new BehaviorSubject(null);
  checked: CommunityAgreement[] = [];
  selectAll = false;
  loading = false;
  filterOptions = [TitleSearchFilter];
  filters$ = new BehaviorSubject<RequestQueryBuilder>(new RequestQueryBuilder());
  pagination?: IPaginationMeta;
  pagination$ = new BehaviorSubject<IPaginationOptions>({ page: 1, limit: 10 });
  linkedUsageIds: string[] = [];

  CommunityAgreement$: Observable<{ commAgreement: CommunityAgreement; checked: boolean }[]> = combineLatest([
    this.filters$,
    this.pagination$,
    this._community$,
    this._linkedIds$,
  ]).pipe(
    tap(() => (this.loading = true)),
    switchMap(async ([query, pagination, community, linkedIds]) => {
      const data: {
        items: { commAgreement: CommunityAgreement; checked: boolean; includedUsage: boolean; linkedUsage: boolean }[];
        meta: IPaginationMeta;
      } = {
        items: [],
        meta: { currentPage: 0, itemCount: 0, itemsPerPage: 0, totalItems: 0, totalPages: 0 },
      };

      const response: PaginatedDto<CommunityAgreement> = community
        ? await this.commAgreementSvc.paginate(community.id, false, pagination, query)
        : { items: [], meta: { currentPage: 0, itemCount: 0, itemsPerPage: 0, totalItems: 0, totalPages: 0 } };

      const commAgreements = response.items;
      data.meta = response.meta;

      if (this.eventId) {
        const usages = await this.eventAgrUsageSvc.paginateEventAgreementUsages(this.eventId, pagination, query);

        usages?.items.forEach(agr => this.linkedUsageIds.push(agr.agreementId));
      } else {
        this.linkedUsageIds = linkedIds;
      }

      for (const agreement of commAgreements) {
        const includeUsage = this.linkedUsageIds.includes(agreement.id);

        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        data.items.push({
          commAgreement: agreement,
          checked: includeUsage,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          includedUsage: includeUsage,
          linkedUsage: includeUsage,
        });
      }

      //this.verifySelectAllCheck(data.items);
      return data;
    }),
    tap(data => (this.pagination = data.meta)),
    map(data => data?.items || []),
    tap(() => (this.loading = false))
  );

  viewAgreement(commAgreement: CommunityAgreement) {
    this.matDialog.open(ViewCommunityAgreementDialog, {
      data: { commAgreement },
      width: '750px',
      maxWidth: '90%',
    });
  }

  itemChecked(item: { commAgreement: CommunityAgreement; checked: boolean }, checked: boolean) {
    item.checked = checked;
    const index = this.checked.findIndex(i => i.id === item.commAgreement.id);
    if (index > -1 && !checked) {
      this.checked.splice(index, 1);
    }
    if (index === -1 && checked) {
      this.checked.push(item.commAgreement);
    }
    if (this.linkedUsageIds.includes(item.commAgreement.id)) item.checked = false;
  }

  async clear() {
    this.checked = [];
    this.pagination$.next(this.pagination$.value);
  }

  async linkAgreements() {
    const dialog = this.matDialog.open(SimpleDialog, {
      data: {
        showCloseButton: false,
        title: 'Confirm Selection',
        subtitle: 'Are you sure you want to link following Agreement(s)?',
        content: this.checked.map(item => item.title).join(', '),
        buttons: [
          { label: 'Cancel', role: 'no' },
          { label: 'Confirm', role: 'yes' },
        ],
      } as DialogData,
      width: '100%',
      maxWidth: '400px',
    });

    if ((await toPromise(dialog.afterClosed())) === 'yes') {
      this.confirmed.emit(this.checked);
    }
  }

  rowClick(item: { commAgreement: CommunityAgreement; checked: boolean }) {
    item.checked = !item.checked;
    this.itemChecked(item, item.checked);
  }
}
