<greco-table [data]="mail" [highlight]="true" [loading]="loading" (rowClick)="openActivity($event)">
  <mat-icon *grecoTableCol="''; fitContent:true; let email" [matTooltip]="email.id">email</mat-icon>

  <p *grecoTableCol="'To'; let email" [matTooltip]="email.to">{{email.to}}</p>

  <p *grecoTableCol="'From'; let email" [matTooltip]="email.from">{{email.from}}</p>

  <p *grecoTableCol="'Subject'; let email" [matTooltip]="email.subject">{{email.subject}}</p>

  <p *grecoTableCol="'Timestamp'; let email" [matTooltip]="email.created | date: 'full'">
    {{email.created | date }} {{email.created | date: 'shortTime'}}
  </p>
</greco-table>

<mat-paginator
  *ngIf="pagination?.totalItems"
  showFirstLastButtons
  [length]="pagination!.totalItems || 0"
  [pageSize]="pagination!.itemsPerPage || 10"
  [pageSizeOptions]="[10, 20, 50]"
  (page)="loadPage($event.pageIndex + 1, $event.pageSize)"
></mat-paginator>
