import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MailActivity, MailSent } from '@greco/nestjs-mail-utils';
import { SimpleDialog } from '@greco/ui-dialog-simple';
import Handlebars, { compile } from 'handlebars';
import * as moment from 'moment-timezone';
import { IPaginationMeta } from 'nestjs-typeorm-paginate';
import { MailService } from '../../services';

@Component({
  selector: 'greco-mail-activity-page',
  templateUrl: './activity.page.html',
  styleUrls: ['./activity.page.scss'],
})
export class ActivityPage implements OnInit {
  constructor(private mailSvc: MailService, private dialog: MatDialog) {}

  @Input() mail!: MailSent;
  activity: MailActivity[] = [];

  pagination: null | IPaginationMeta = null;

  loading = true;

  async loadPage(page: number, limit: number) {
    this.loading = true;
    ({ items: this.activity, meta: this.pagination } = await this.mailSvc.paginateActivity(
      { page, limit },
      this.mail.id
    ));
    this.loading = false;
  }

  previewEmail() {
    const content = compile(this.mail.template.htmlTemplateString)(JSON.parse(this.mail.templateData));
    this.dialog.open(SimpleDialog, { data: { content } });
  }

  async ngOnInit(): Promise<void> {
    Handlebars.registerHelper({
      formatDate: (date: any, format: string) => (date ? moment(date).tz('America/Toronto').format(format) : ''),
      formatCurrency: (value: any) => {
        const dollarValueFormat = Math.abs(Number(value) / 100).toFixed(2);
        return (value < 0 ? '-' : '') + '$' + dollarValueFormat;
      },
    });
    await this.loadPage(1, 10);
  }
}
