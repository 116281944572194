<greco-filter-bar
  [hideSeparator]="true"
  [filterOptions]="filterOptions"
  (changed)="filters$.next($event[1]); onFilterApplied()"
  style="position: sticky; top: 56px; z-index: 10; background-color: var(--background-color, white)"
>
  <button
    mat-stroked-button
    color="primary"
    style="height: 32px"
    (click)="createStation()"
    *ngIf="canCreateStation$ | async"
  >
    <mat-icon>add</mat-icon>
    <span>Create Station</span>
  </button>
</greco-filter-bar>

<greco-table
  #stationsTable
  [highlight]="true"
  [loading]="loading"
  [data]="(stations$ | async) || []"
  [activeSort]="(sort$ | async) || undefined"
  (sortChange)="sort$.next($event)"
  (rowClick)="openStation($event)"
>
  <mat-icon *grecoTableCol="''; fitContent: true; let station" [matTooltip]="station.id">place</mat-icon>
  <p *grecoTableCol="'Title'; let station">{{ station.title ? station.title : 'Unknown Title' }}</p>

  <p *grecoTableCol="'Recent Check Ins'; let station">{{ station.recentCheckIns ? station.recentCheckIns : '-' }}</p>

  <p *grecoTableCol="'Total Check Ins'; let station">{{ station.totalCheckIns ? station.totalCheckIns : '-' }}</p>

  <p *grecoTableCol="'Check In Bookings'; let station">{{ station.checkInBookings ? 'Yes' : 'No' }}</p>

  <p *grecoTableCol="'Created Date'; let station">{{ station.created | date }}</p>

  <ng-container *grecoTableCol="''; let resource; fitContent: true; stickyEnd: true">
    <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopImmediatePropagation()">
      <mat-icon>more_vert</mat-icon>
    </button>

    <mat-menu #menu>
      <button mat-menu-item (click)="updateStation(resource)" *ngIf="canUpdateStations$ | async">
        <mat-icon>edit</mat-icon>
        <span>Edit Title</span>
      </button>

      <button mat-menu-item (click)="toggleAutoCheckIn(resource)">
        <mat-icon>{{ resource?.checkInBookings ? "check_box" : "check_box_outline_blank" }}</mat-icon>
        <span>{{ resource?.checkInBookings ? "Disable" : "Enable" }} Booking Check-In</span>
      </button>

      <button type="button" mat-menu-item (click)="deleteStation(resource)" *ngIf="isSuperAdmin$ | async">
        <mat-icon>gavel</mat-icon>
        <span>Delete</span>
      </button>
    </mat-menu>
  </ng-container>
</greco-table>

<mat-paginator
  *ngIf="pagination?.totalItems"
  showFirstLastButtons
  [length]="pagination!.totalItems || 0"
  [pageSize]="pagination!.itemsPerPage || 10"
  [pageSizeOptions]="[10, 20, 50]"
  (page)="page$.next({ page: $event.pageIndex + 1, limit: $event.pageSize })"
></mat-paginator>
