<greco-simple-dialog [data]="dialogData">
  <div *ngIf="!data.referredById" style="display: flex; gap: 12px">
    <mat-checkbox color="primary" style="margin-bottom: 16px" [checked]="includeSoldBy" (change)="toggleSoldBy()">
      Include Sold By
    </mat-checkbox>

    <mat-checkbox
      color="primary"
      style="margin-bottom: 16px"
      [checked]="includeReferredBy"
      (change)="toggleReferredBy()"
    >
      Include Referred By
    </mat-checkbox>
  </div>

  <mat-form-field *ngIf="includeReferredBy && !data.referredById" style="width: 100%">
    <mat-label>User</mat-label>
    <greco-contact-picker [communityId]="this.data.communityId" (contact)="setReferredBy($event?.user?.id || null)">
    </greco-contact-picker>
  </mat-form-field>

  <div
    style="
      padding: 8px 12px;
      background-color: #f5f5f5;
      border: 2px solid #e0e0e0;
      border-radius: 4px;
      text-overflow: ellipsis;
    "
  >
    <p style="max-width: 100%; white-space: nowrap; overflow-x: auto; margin: 0">{{ link }}</p>
  </div>

  <div style="display: flex; justify-content: end; gap: 16px; align-items: center; margin-top: 16px">
    <button mat-stroked-button color="primary" (click)="close()">Cancel</button>
    <button mat-flat-button color="primary" [disabled]="processing" (click)="submit()">Copy Link</button>
  </div>
</greco-simple-dialog>
