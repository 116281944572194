import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  AccountLink,
  AccountLinkingSecurityResource,
  AccountLinkingSecurityResourceAction,
} from '@greco/account-linking';
import { User } from '@greco/identity-users';
import { UserService } from '@greco/ngx-identity-auth';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { ProfileDetailsUpdateDialog } from '@greco/ngx-identity-users';
import { PropertyListener } from '@greco/property-listener-util';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AccountLinkingService } from '../../services';

@Component({
  selector: 'greco-linked-accounts-owned-table',
  templateUrl: './linked-accounts-owned-table.component.html',
  styleUrls: ['./linked-accounts-owned-table.component.scss'],
})
export class LinkedAccountsOwnedTableComponent {
  constructor(
    private matDialog: MatDialog,

    private userSvc: UserService,
    private linkingSvc: AccountLinkingService,
    private comSecuritySvc: CommunitySecurityService
  ) {}

  @PropertyListener('user') user$ = new BehaviorSubject<User | null>(null);
  @Input() user!: User;

  @Input() loading = false;
  @Input() communityId?: string;

  @Input() ownedLinks: AccountLink[] = [];

  @Output() refresh = new EventEmitter();

  signedInUser$ = this.userSvc.user$;
  isUser$ = combineLatest([this.user$, this.signedInUser$]).pipe(
    switchMap(async ([user, signedInUser]) => {
      if (!user || !signedInUser) return false;
      return user.id === signedInUser.id;
    })
  );

  canRevoke$ = combineLatest([this.isUser$, this.signedInUser$]).pipe(
    switchMap(async ([isUser]) => {
      if (isUser) return true;
      else
        return (
          await this.comSecuritySvc.communitiesWithAccess(
            AccountLinkingSecurityResource.key,
            AccountLinkingSecurityResourceAction.REVOKE
          )
        )?.length > 0
          ? true
          : false;
    })
  );

  canUpdate$ = combineLatest([this.isUser$, this.signedInUser$]).pipe(
    switchMap(async ([isUser]) => {
      if (isUser) return true;
      else
        return (
          await this.comSecuritySvc.communitiesWithAccess(
            AccountLinkingSecurityResource.key,
            AccountLinkingSecurityResourceAction.UPDATE
          )
        )?.length > 0
          ? true
          : false;
    })
  );

  async userProfileUpdate(link: AccountLink | undefined) {
    if (link?.account && !link.account.email) {
      this.matDialog.open(ProfileDetailsUpdateDialog, {
        width: '1000px',
        data: {
          title: 'Profile Information',
          user: link.account,
          readOnly: !!link.account.email,
        } as { title: string; user: User; readOnly: boolean },
      });
    }
  }

  async revokeAccess(link: AccountLink) {
    const response = await this.linkingSvc.revokeAccess(link.id);
    if (response) this.refresh.emit(link);
  }
}
