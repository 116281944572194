<ng-container *grecoLet="canCreateBookings$ | async as canCreateBookings">
  <greco-box style="padding: 19px" *ngIf="contact; else selectUser">
    <div class="info-container">
      <div class="info">
        <div class="image">
          <greco-user-avatar
            matPrefix
            size="large"
            [showOnlineStatus]="false"
            [user]="contact.user || null"
          ></greco-user-avatar>
        </div>

        <div class="info-data">
          <div class="name">
            <span>{{ contact.user?.displayName }}</span>
          </div>

          <div class="email" style="margin-top: -8px">{{ contact.user?.contactEmail }}</div>
          <div *ngIf="contact.user?.phoneNumber as phoneNumber" class="phoneNumber">{{ phoneNumber | phone }}</div>

          <ng-container *ngIf="lastCheckIn$ | async as lastCheckIn">
            <div class="check-in-info">
              <mat-chip matTooltip="Previous Check In">
                <small
                  style="padding-bottom: 0; margin-bottom: 0; white-space: no-wrap; overflow: none; line-height: 12px"
                >
                  <p
                    style="
                      padding-bottom: 0;
                      margin-bottom: 1px;
                      white-space: no-wrap;
                      overflow: none;
                      line-height: 12px;
                    "
                  >
                    <strong>Previous Check-In </strong>
                  </p>

                  <p
                    style="
                      padding-bottom: 0;
                      margin-bottom: 1px;
                      white-space: no-wrap;
                      overflow: none;
                      line-height: 12px;
                    "
                  >
                    {{ lastCheckIn.timestamp | date : 'short' }}

                    <a [routerLink]="['/admin/community', contact.community.id, 'stations', lastCheckIn.stationId]">
                      {{ lastCheckIn.station?.title }}
                    </a>
                  </p>
                </small>
              </mat-chip>
            </div>
          </ng-container>

          <ng-container *ngIf="canReadSubscriptions$ | async">
            <div class="subscriptions" *ngIf="subscriptions$ | async as subscriptions">
              <mat-chip-list *ngFor="let subscription of subscriptions.items">
                <mat-chip
                  *grecoLet="subscription | nextRenewal | async as nextCycle"
                  matTooltip="{{ subscription.status }} ({{ nextCycle }})"
                  (click)="openSubscriptionSummary(subscription, community?.id || '')"
                >
                  {{ subscription.items ? subscription.items[0].displayName : 'Unknown Product' }}
                  <mat-icon style="margin-left: 4px; line-height: 18px; font-size: 18px; width: 18px; height: 18px">
                    open_in_new
                  </mat-icon>
                </mat-chip>
              </mat-chip-list>
            </div>
          </ng-container>
        </div>

        <div class="actions">
          <div class="buttons" *ngIf="canCreatePurchase$ | async" style="display: flex; gap: 12px">
            <button *ngIf="autoCheckIn && !checkedIn" mat-flat-button color="primary" (click)="checkInUserEmit()">
              Check-In
            </button>

            <button mat-flat-button color="primary" (click)="createCustomerPurchase(contact)">
              <span>New Purchase</span>
            </button>

            <button mat-stroked-button [matMenuTriggerFor]="stationMenu" color="primary">
              Quick Actions
              <mat-icon>arrow_drop_down</mat-icon>
            </button>

            <mat-menu #stationMenu>
              <ng-content></ng-content>
              <ng-container *grecoLet="isSuper$ | async as superUser">
                <ng-container
                  *grecoLet="(!contact.subscribedDate || (canManageAuth$ | async)) === false as canManageAuth"
                >
                  <button
                    *grecoLet="superUser === false && canManageAuth as readOnly"
                    mat-menu-item
                    color="primary"
                    (click)="userProfileUpdate(contact.user, readOnly)"
                  >
                    <mat-icon>account_circle</mat-icon>
                    <span>Contact</span>
                  </button>
                </ng-container>
              </ng-container>

              <ng-container *grecoLet="isSuper$ | async as superUser">
                <ng-container
                  *grecoLet="(!contact.subscribedDate || (canManageAuth$ | async)) === false as canManageAuth"
                >
                  <button
                    *grecoLet="superUser === false && canManageAuth as readOnly"
                    mat-menu-item
                    color="primary"
                    (click)="userAccountUpdate(contact, readOnly)"
                  >
                    <mat-icon>manage_accounts</mat-icon>
                    <span>Account</span>
                  </button>
                </ng-container>
              </ng-container>

              <button
                *ngIf="
                  !(
                    (isSuper$ | async) === false &&
                    (!contact.subscribedDate || (canManageContact$ | async) === false)
                  ) &&
                  (!contact.memberNumber || !contact.memberNumber.length)
                "
                mat-menu-item
                color="primary"
                header
                (click)="openDialog()"
              >
                <mat-icon>create</mat-icon>
                <span>Member Number</span>
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
    </div>

    <br />

    <div class="alerts-container">
      <!-- Check In Stations alerts appear in this order:

      Payment Disputes:
      Level: ERROR ➝ shown if user has a payment dispute with status "OPEN" or "UNDER_REVIEW"

      Failed Purchases:
      Level: ERROR ➝ shown if user has any failed purchases

      Pending Bookings:
      Level: ERROR ➝ shown if user has a pending booking in the check in window

      Expiring Payment Methods:
      Level: WARNING ➝  shown if user has a payment method with Upcoming Expiry Date
      Level: ERROR ➝  shown if user has a payment method that is Expired

      Unconfirmed Bookings:
      Level: WARNING ➝ show if user has unconfirmed bookings

      No Profile Picture:
      Level: WARNING ➝ shown if user has no profile picture

      Check In Bookings:
      Level: INFO : shown when user isn't checked in their upcoming event.
       -->

      <!-- No agreements error -->
      <!-- This error appears as soon as a user has NO agreements (https://app.clickup.com/t/86dqqmbvh) -->
      <greco-alert
        *ngIf="(hasAgreements$ | async) === false"
        color="error"
        subtitle="This member has not signed any agreement with the community."
        [config]="{
          title: 'No Agreements',
          type: $any('DANGER'),
          description: 'This member has not signed any agreement with the community'
        }"
      ></greco-alert>

      <!-- Errors -->
      <ng-container *ngIf="!contact.user?.address?.line1">
        <ng-container *grecoLet="(!contact.subscribedDate || (canManageAuth$ | async)) === false as canManageAuth">
          <greco-alert
            [config]="{
              title: 'Missing Profile Information',
              type: $any('DANGER'),
              description: 'Please complete your profile to access the club'
            }"
          >
            <button
              *grecoLet="canManageAuth as readOnly"
              mat-flat-button
              slot="buttons"
              color="primary"
              (click)="userProfileUpdate(contact.user, readOnly)"
            >
              <span>Contact</span>
            </button>
          </greco-alert>
        </ng-container>
      </ng-container>

      <!-- High Priority Errors -->
      <ng-container *grecoLet="highPriorityWarnings$ | async as highPriorityWarnings">
        <greco-alert
          *ngIf="loadingHighPriorityWarnings"
          [config]="{
            title: 'Loading high priority warnings...',
            type: $any('DANGER'),
            description: 'Please wait for warnings to load before sending member off'
          }"
        >
        </greco-alert>

        <greco-no-profile-picture-card
          *ngIf="highPriorityWarnings?.noProfilePicture && contact.user"
          [user]="contact.user"
          (changes)="contact.user = $event"
        ></greco-no-profile-picture-card>

        <!-- Subscriptions -->
        <ng-container *ngIf="canReadSubscriptions$ | async">
          <ng-container *ngIf="highPriorityWarnings?.noMembership as noMembership">
            <ng-container *grecoLet="isGuest$ | async as isGuest">
              <greco-alert
                [config]="{
                  title: isGuest ? 'Guest User' : noMembership.title,
                  type: isGuest ? $any('WARN') : $any('DANGER'),
                  description: isGuest
                    ? 'This user is a guest and doesn\'t currently have a membership'
                    : noMembership.description
                }"
              >
                <button
                  *ngIf="canCreatePurchase$ | async"
                  mat-flat-button
                  slot="buttons"
                  color="primary"
                  (click)="createCustomerPurchase(contact)"
                >
                  Purchase
                </button>
              </greco-alert>
            </ng-container>
          </ng-container>

          <greco-frozen-subscription-alert
            *ngFor="let warning of highPriorityWarnings?.membershipFrozen"
            [isUser]="false"
          >
          </greco-frozen-subscription-alert>
        </ng-container>

        <!-- Purchases/Payments -->
        <greco-outstanding-purchase-card
          *ngFor="let warning of highPriorityWarnings?.failedPurchases"
          [outstandingPurchase]="$any(warning.data)"
          (refreshPurchase)="refreshPurchase($event)"
        ></greco-outstanding-purchase-card>

        <ng-container *ngIf="canReadPurchases$ | async">
          <greco-payment-dispute-card
            *ngFor="let warning of highPriorityWarnings?.paymentDisputes"
            [paymentDispute]="$any(warning.data)"
            [communityId]="contact.community.id"
          >
          </greco-payment-dispute-card>
        </ng-container>

        <!-- Unsigned Agreements -->
        <greco-unsigned-agreement-card
          *ngFor="let warning of highPriorityWarnings?.unsignedAgreements"
          [agreement]="$any(warning.data)"
          (refreshAgreements)="refreshAgreements()"
        ></greco-unsigned-agreement-card>

        <ng-container *ngIf="highPriorityWarnings?.emailBlacklisted as alert">
          <greco-alert
            [config]="{
              title: alert.title,
              type: $any('WARN'),
              description: alert.description
            }"
          >
          </greco-alert>
        </ng-container>
      </ng-container>

      <ng-container *grecoLet="lowPriorityWarnings$ | async as lowPriorityWarnings">
        <ng-container *ngIf="contact.user">
          <greco-expiring-payment-method-alert
            *ngFor="let warning of lowPriorityWarnings?.expiredPaymentMethods"
            [expired]="true"
            [user]="contact.user"
            [paymentMethod]="$any(warning.data)"
          ></greco-expiring-payment-method-alert>

          <greco-expiring-payment-method-alert
            *ngFor="let warning of lowPriorityWarnings?.expiringPaymentMethods"
            [user]="contact.user"
            [paymentMethod]="$any(warning.data)"
          ></greco-expiring-payment-method-alert>
        </ng-container>

        <ng-container *ngIf="canCreateBookings">
          <greco-unconfirmed-booking-alert
            *ngFor="let warning of lowPriorityWarnings?.pastPendingBookings"
            [booking]="$any(warning.data)"
            (refreshBookings)="refreshBookings($event)"
          ></greco-unconfirmed-booking-alert>

          <greco-alert
            *ngIf="contact.user?.birthday && moment(contact.user?.birthday).add(16, 'years').isAfter(moment())"
            [config]="{
              title: 'Member is under 16 years old',
              type: $any('WARN'),
              description: 'Please ensure member has permission to access the club'
            }"
          ></greco-alert>

          <greco-check-in-booking-alert
            *ngFor="let warning of lowPriorityWarnings?.bookingsToCheckIn"
            [booking]="$any(warning.data)"
            (refreshBookings)="refreshBookings($event)"
          ></greco-check-in-booking-alert>
        </ng-container>
      </ng-container>
    </div>
  </greco-box>
</ng-container>

<ng-template #selectUser>
  <greco-box style="text-align: center">
    <greco-empty-placeholder text="Select a user from the search bar!"></greco-empty-placeholder>
  </greco-box>
</ng-template>
