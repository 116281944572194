import { Component, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { toPromise } from '@greco-fit/util';
import {
  BookingOption,
  BookingOptionSecurityResource,
  BookingOptionSecurityResourceAction,
} from '@greco/booking-events';
import { CommunityAgreementsService } from '@greco/ngx-community-agreements';
import { SecurityService } from '@greco/ngx-security-util';
import { PropertyListener } from '@greco/property-listener-util';
import { IPaginationMeta, IPaginationOptions } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, combineLatest, ReplaySubject, Subject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { LinkAgreementDialog, ViewCommunityAgreementDialog } from '../../dialogs';
import { BookingOptionCommunityAgreementsUsageService } from '../../services/booking-option-community-agreements-usage.service';

@Component({
  selector: 'greco-booking-option-agreements',
  templateUrl: './booking-option-agreements.component.html',
  styleUrls: ['./booking-option-agreements.component.scss'],
})
export class BookingOptionAgreementsComponent implements OnDestroy {
  constructor(
    private dialog: MatDialog,
    private snacks: MatSnackBar,
    private securitySvc: SecurityService,
    private agreementsSvc: CommunityAgreementsService,
    private agreementUsageSvc: BookingOptionCommunityAgreementsUsageService
  ) {}

  @PropertyListener('communityId') private _communityId$ = new ReplaySubject<string>(1);
  @Input() communityId!: string;

  loading = false;
  linkedIds: string[] = [];

  private _bookingOption$ = new BehaviorSubject<BookingOption | null>(null);

  private _onDestroy$ = new Subject<void>();
  refresh$ = new BehaviorSubject(null);

  pagination$ = new BehaviorSubject<IPaginationOptions>({ page: 1, limit: 10 });
  paginationMeta?: IPaginationMeta;

  private _bookingOption?: BookingOption;
  @Input() set bookingOption(bookingOption) {
    this._bookingOption = bookingOption;
    this._bookingOption$.next(bookingOption || null);
  }
  get bookingOption() {
    return this._bookingOption;
  }

  canManage$ = this._communityId$.pipe(
    switchMap(async communityId => {
      return communityId
        ? await this.securitySvc.hasAccess(
            BookingOptionSecurityResource.key,
            BookingOptionSecurityResourceAction.MANAGE,
            { communityId },
            true
          )
        : false;
    })
  );

  agreements$ = combineLatest([this.pagination$, this._bookingOption$, this.refresh$]).pipe(
    tap(() => setTimeout(() => (this.loading = true))),
    switchMap(([options, bookingOption]) =>
      this.agreementUsageSvc.paginateBookingOptionAgreementUsages(bookingOption?.id || '', options)
    ),
    tap(({ meta }) => setTimeout(() => (this.paginationMeta = meta))),
    map(({ items }) => items),
    tap(() => setTimeout(() => (this.loading = false))),
    tap(response => {
      response.forEach((element: any) => {
        this.linkedIds.push(element.agreementId);
      });
    })
  );

  async linkAgreement() {
    if (this.bookingOption) {
      const community = this.bookingOption.community;

      const dialog = this.dialog.open(LinkAgreementDialog, {
        data: {
          community: community,
          linkedIds: this.linkedIds,
          bookingOptionId: this.bookingOption.id,
        },
        width: '1000px',
        maxWidth: '90%',
      });

      const response = await toPromise(dialog.afterClosed());

      if (response?.length) {
        let success = 0;
        let failure = 0;

        for (const item of response) {
          try {
            await this.agreementUsageSvc.linkAgreementToBookingOption(this.bookingOption, item.id);
            success++;
          } catch (err) {
            console.error(err);
            failure++;
          }
        }

        this.snacks.open(
          'Successfully linked ' + success + ' agreements.',
          failure === 0 ? 'Ok!' : failure + ' errors!',
          {
            duration: 2500,
            panelClass: failure === 0 ? 'mat-primary' : 'mat-warn',
          }
        );

        this.refresh$.next(null);
      }
    }
  }

  async viewAgreement(agreementId: string) {
    const agreement = await this.agreementsSvc.getOne(agreementId);
    this.dialog.open(ViewCommunityAgreementDialog, {
      data: { commAgreement: { commAgreement: agreement } },
      width: '750px',
      maxWidth: '90%',
    });
  }

  async deleteAgreementUsage(usageId: string) {
    const response = await this.agreementUsageSvc.deleteBookingOptionAgreementUsage(usageId);
    if (!response) {
      this.snacks.open('Something went wrong! Please try again.', 'Ok', { duration: 2500, panelClass: 'mat-warn' });
    } else {
      this.snacks.open('Agreement usage removed from event', 'Ok', { duration: 2500, panelClass: 'mat-primary' });
      this.refresh$.next(null);
    }
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }
}
