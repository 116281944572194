import { Component, Injectable, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { toPromise } from '@greco-fit/util';
import { EVENT_SERIES_SECURITY_RESOURCE, Event, EventSeriesSecurityResourceAction } from '@greco/booking-events';
import { Community } from '@greco/identity-communities';
import { BuildDateFilter, BuildSearchFilter } from '@greco/ngx-filters';
import { CommunitySecurityService } from '@greco/ngx-identity-community-staff-util';
import { PropertyListener } from '@greco/property-listener-util';
import { RequestQueryBuilder } from '@nestjsx/crud-request';
import { IPaginationMeta } from 'nestjs-typeorm-paginate';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { CreateEventDialog, CreateEventFromTemplateDialog } from '../../dialogs';
import { CalendarService, EventService, EventTemplateService } from '../../services';
import { EventTitleFilter } from '../events/events.page';
import { ResourceFilter } from '../events/filters/resource.filter';
import { TagsSelectFilter } from '../events/filters/tags.filter';

@Injectable({ providedIn: 'any' })
export class SeriessSearchFilter extends BuildSearchFilter('SeriessSearchFilter', {
  properties: ['title', 'description'],
  propertyLabels: ['Title', 'Description'],
}) {}

@Injectable({ providedIn: 'any' })
export class SeriesStartDateFilter extends BuildDateFilter('SeriesStartDateFilter', {
  label: 'Series Start',
  shortLabel: 'Start',
  description: '',
  properties: ['startDate'],
}) {}

@Injectable({ providedIn: 'any' })
export class SeriesEndDateFilter extends BuildDateFilter('SeriesEndDateFilter', {
  label: 'Series End',
  shortLabel: 'End',
  description: '',
  properties: ['endDate'],
}) {}

@Component({
  selector: 'greco-series-page',
  templateUrl: './series.page.html',
  styleUrls: ['./series.page.scss'],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class SeriesPage implements OnChanges, OnDestroy {
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private eventSvc: EventService,
    private tagFilter: TagsSelectFilter,
    private calendarSvc: CalendarService,
    private resourceFilter: ResourceFilter,
    private comSecSvc: CommunitySecurityService,
    private eventTemplateSvc: EventTemplateService
  ) {}

  private _community$ = new BehaviorSubject<Community | null>(null);
  @Input() get community() {
    return this._community$.value;
  }
  set community(community) {
    this._community$.next(community);
    this.resourceFilter.communityId = community?.id;
    this.tagFilter.communityId = community?.id;
  }
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  calendarsList$ = this._community$.pipe(
    switchMap(async community => (community ? await this.calendarSvc.getManySecuredCached(community.id) : null))
  );

  canCreateCustomSeries$ = this._community$.pipe(
    switchMap(async com =>
      com
        ? await this.comSecSvc.hasAccess(
            com.id,
            EVENT_SERIES_SECURITY_RESOURCE,
            EventSeriesSecurityResourceAction.CREATE_CUSTOM
          )
        : false
    )
  );

  canCreateSeries$ = this._community$.pipe(
    switchMap(async com =>
      com
        ? await this.comSecSvc.hasAccess(
            com.id,
            EVENT_SERIES_SECURITY_RESOURCE,
            EventSeriesSecurityResourceAction.CREATE
          )
        : false
    )
  );

  filterOptions = [
    SeriessSearchFilter,
    EventTitleFilter,
    SeriesStartDateFilter,
    SeriesEndDateFilter,
    ResourceFilter,
    TagsSelectFilter,
  ];

  filters$ = new BehaviorSubject<RequestQueryBuilder>(new RequestQueryBuilder());
  page$ = new BehaviorSubject<{ page: number; limit: number }>({ page: 1, limit: 10 });

  @PropertyListener('includeInactive') private includeInactive$ = new BehaviorSubject<boolean>(false);
  includeInactive = false;

  initialCalendars: string[] = [];

  pagination: null | IPaginationMeta = null;

  events$ = combineLatest([this._community$, this.filters$, this.page$, this.includeInactive$]).pipe(
    tap(() => (this.loading = true)),
    debounceTime(500),
    switchMap(async ([community, filters, pagination, includeInactive]) => {
      const calendarId = await this.calendarIdfromRoute();
      if (filters.queryObject['s']) {
        filters.queryObject['s'] = filters.queryObject['s']?.replace('resources.id', 'resourceAssignments.resourceId');
      }
      return community
        ? await this.eventSvc.paginateSeries(filters, community.id, calendarId, pagination, includeInactive)
        : null;
    }),
    tap(data => (this.pagination = data?.meta || null)),
    map(data => data?.items || []),
    tap(() => (this.loading = false))
  );

  loading = true;

  async createSeries() {
    const communityId = this.community?.id;
    if (!communityId) return;

    const dialog = this.dialog.open(CreateEventDialog, {
      data: { communityId: communityId, forSeries: true },
      width: '750px',
      maxWidth: '90%',
    });
    if (await toPromise(dialog.afterClosed())) this.refresh();
  }

  async createSeriesFromTemplate(templateId: string, calendarId: string) {
    if (!this.community?.id) return;

    const eventTemplate = await this.eventTemplateSvc.getOne(templateId);

    if (this.community.id != eventTemplate?.communityId) return;

    await toPromise(
      this.dialog
        .open(CreateEventFromTemplateDialog, {
          data: {
            forSeries: true,
            calendarId: calendarId,
            eventTemplate: eventTemplate,
            communityId: this.community.id,
          },
          width: '750px',
          maxWidth: '90%',
        })
        .afterClosed()
    );
    this.refresh();
  }

  async openSeries(event: Event) {
    const url = this.router.createUrlTree([event?.id], { relativeTo: this.route });
    window.open(this.router.serializeUrl(url), '_blank');
  }

  async calendarIdfromRoute() {
    const query = await toPromise(this.route.queryParamMap);
    const calendarId = query.getAll('calendarIds');
    this.initialCalendars = calendarId;
    return calendarId;
  }

  onFilterApplied() {
    if (this.paginator !== undefined) this.paginator.firstPage();
  }

  ngOnDestroy() {
    this.filters$.complete();
    this.page$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.community.previousValue !== changes.community.currentValue) {
      this.refresh();
    }
  }

  refresh() {
    this.filters$.next(this.filters$.value);
  }
}
