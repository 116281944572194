<greco-collapsible-section [header]="{ title: 'Booking Option Details', icon: 'info' }">
  <greco-form-save-buttons
    header
    [form]="detailsForm.form"
    [saveAction]="detailsForm.save"
    [resetValue]="detailsForm.resetValue"
  ></greco-form-save-buttons>

  <greco-booking-option-details-form
    #detailsForm
    [bookingOption]="bookingOption"
    (saved)="saved()"
  ></greco-booking-option-details-form>
</greco-collapsible-section>

<greco-collapsible-section [header]="{ title: 'Booking & Cancellation', icon: 'confirmation_number' }">
  <greco-form-save-buttons
    header
    [form]="bookingForm.form"
    [saveAction]="bookingForm.save"
    [resetValue]="bookingForm.resetValue"
  ></greco-form-save-buttons>
  <greco-booking-option-booking-and-cancellation-form
    #bookingForm
    [bookingOption]="bookingOption"
    (saved)="saved()"
  ></greco-booking-option-booking-and-cancellation-form>
</greco-collapsible-section>

<greco-booking-option-agreements
  [bookingOption]="bookingOption"
  [communityId]="bookingOption.community?.id || ''"
></greco-booking-option-agreements>
